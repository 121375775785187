import React, { useState, useRef, useEffect, useContext } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
    Grid,
    Typography,
    Hidden,
    Dialog,
    Button,
    Collapse,
    Box,
    Radio,
    TextField,
    FormControl
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Cancel as CancelIcon } from '@material-ui/icons';

import Alert from '@material-ui/lab/Alert';
import Carousel from 'react-material-ui-carousel';
import axios from 'axios';
import { CookiesProvider, useCookies } from 'react-cookie';

import Loader from '../../components/loader/Loader';

import {
    HighlightsSection,
    HeroSection,
    CourseComparisonSection,
    SyllabusSection,
    ReviewsSection,
    MobileFooter,
    UpcomingBatches,
    TopRibbonSection,
    PaymentSection,
    EnquiryFormMob,
    KidsDevelopSectionSmall,
    MentorsSection,
    AboutSection
} from './sections/CoursePageSections';
import StudiesSection from '../../sections/section-studies/StudiesSection';

import { CourseCard } from '../../components/card/Card';
import Faqs from '../../components/faqs/Faqs';

import { CountryContext } from '../../contexts/country/CountryContext';
import {
    API_URL,
    MAIL_URL,
    detectMob,
    removeHTMLNewLineLArray,
    elDistanceToTop,
    isObjEmpty,
    COUNTRIES_WITH_CODES
} from '../../constants/constants';

import './CoursePage.scss';

const CoursePage = () => {
    const [activeTab, setActiveTab] = useState(0),
        [coursePageResponse, setCoursePageResponse] = useState(null),
        [faqOptionsData, setFaqOptionsData] = useState([]),
        [syllabusData, setSyllabusData] = useState([]),
        [faqData, setFaqData] = useState([]),
        [reviewsData, setReviewsData] = useState([]),
        [mentorsData, setMentorsData] = useState([]),
        [batchData, setBatchData] = useState([]),
        [projectData, setProjectData] = useState([]),
        [productBenifitsData, setProductBenefitsData] = useState({}),
        [toolsData, setToolsData] = useState([]),
        [effortAndDurationData, setEffortAndDurationData] = useState({}),
        [ratingsReviewsData, setRatingsReviewsData] = useState({}),
        [titleSubtitleTextData, setTitleSubtitleTextData] = useState({}),
        [coursePrice, setCoursePrice] = useState({}),
        [hiddenFieldsForFormData, setHiddenFieldsForFormData] = useState({}),
        [paymentPlans, setPaymentPlans] = useState({}),
        [alertOpen, setAlertOpen] = useState(false),
        [open, setOpen] = useState(false),
        [coursePlanId, setCoursePlanId] = useState(),
        [showLoader, setShowLoader] = useState(true),
        [cookies, setCookie] = useCookies(['isBrochureEnquiry']),
        [countryState, setCountryState] = useContext(CountryContext),
        [selectedBatch, setSelectedBatch] = useState(),
        [courseName, setCourseName] = useState(),
        [courseQuestions, setCourseQuestions] = useState([]),
        [openDownloadDialog, setOpenDownloadDialog] = useState(false),
        [countryCode, setCountryCode] = useState(),
        history = useHistory(),
        currentURL = useLocation().pathname,
        courseDescription = useLocation().pathname.split('/')[3];

    const stickyForm = useRef();

    const setCoursePageData = () => {
        let webDevJSON = {};
        for (let i = 0; i < coursePageResponse.length; i++) {
            if (coursePageResponse[i].productInfo.flow === countryState.code) {
                webDevJSON = coursePageResponse[i];
                break;
            }
        }
        if (isObjEmpty(webDevJSON)) {
            alert('This course does not exist for current country');
            history.push('/');
            return;
        }

        let syllabus = [],
            faqOptions = [],
            faq = [],
            reviews = [],
            mentors = [],
            batchDetail = [],
            projectDetails = [],
            productBenefits = {},
            tools = [],
            effortAndDuration = {},
            ratingsReviews = {},
            titleSubtitleText = {},
            price = {},
            paymentPlansData = [],
            hiddenFieldsForForm,
            courseName = '',
            courseQs = [];

        for (let i = 0; i < webDevJSON.syllabus.length; i++) {
            syllabus.push({
                title: webDevJSON.syllabus[i].heading,
                listData: removeHTMLNewLineLArray(
                    webDevJSON.syllabus[i].description
                )
            });
        }

        for (let i = 0; i < webDevJSON.faqs.length; i++) {
            if (webDevJSON.faqs[i].status === 'active') {
                if (!faqOptions.includes(webDevJSON.faqs[i].category)) {
                    faqOptions.push(webDevJSON.faqs[i].category);
                }
                faq.push({
                    title: webDevJSON.faqs[i].question,
                    listData: [webDevJSON.faqs[i].answer],
                    category: webDevJSON.faqs[i].category
                });
            }
        }

        for (let i = 0; i < webDevJSON.reviews.length; i++) {
            reviews.push({
                designation: webDevJSON.reviews[i].designation,
                imageUrl: webDevJSON.reviews[i].imageUrl,
                name: webDevJSON.reviews[i].name,
                review: webDevJSON.reviews[i].review
            });
        }

        mentors = webDevJSON.mentor || [];

        batchDetail = webDevJSON.batch || [];

        productBenefits = webDevJSON.productInfo.productBenefits || {};

        tools = webDevJSON.productInfo.tools || [];

        effortAndDuration = {
            effort: webDevJSON.productInfo.effort,
            duration: webDevJSON.productInfo.duration
        };

        ratingsReviews = {
            ratings: webDevJSON.productInfo.rating,
            reviews: webDevJSON.productInfo.reviews
        };

        titleSubtitleText = {
            title: webDevJSON.seo.h1,
            subtitle: webDevJSON.seo.h2
        };

        price = {
            price: webDevJSON.pricing.mrp,
            currency: webDevJSON.pricing.currency
        };

        paymentPlansData = webDevJSON.pricing.paymentPlans || [];

        hiddenFieldsForForm = {
            course_id: webDevJSON._id.$oid,
            product_category: webDevJSON.productInfo.productCategory,
            product_group: webDevJSON.productInfo.productGroupShortName,
            product: webDevJSON.productInfo.shortName,
            mode_of_training: webDevJSON.productInfo.training_mode,
            brochure: webDevJSON.productInfo.brochure
        };

        courseName = webDevJSON.productInfo.name || '';

        for (let i = 0; i < webDevJSON.seo.courseQuestions.length; i++) {
            if (webDevJSON.seo.courseQuestions[i].status === 'active') {
                //if (!faqOptions.includes(webDevJSON.faqs[i].category)) {
                //    faqOptions.push(webDevJSON.faqs[i].category);
                //}
                courseQs.push({
                    title: webDevJSON.seo.courseQuestions[i].question,
                    listData: [webDevJSON.seo.courseQuestions[i].answer],
                    category: webDevJSON.seo.courseQuestions[i].category
                });
            }
        }

        setFaqOptionsData(faqOptions);
        setSyllabusData(syllabus);
        setFaqData(faq);
        setReviewsData(reviews);
        setMentorsData(mentors);
        setBatchData(batchDetail);
        setProjectData(projectDetails);
        setProductBenefitsData(productBenefits);
        setToolsData(tools);
        setEffortAndDurationData(effortAndDuration);
        setRatingsReviewsData(ratingsReviews);
        setTitleSubtitleTextData(titleSubtitleText);
        setCoursePrice(price);
        setPaymentPlans(paymentPlansData);
        setHiddenFieldsForFormData(hiddenFieldsForForm);
        setCourseName(courseName);
        setCourseQuestions(courseQs);
    };

    const handleTabClick = (e) => {
        if (detectMob() === true) {
            return;
        }
        const scrollToElement = e.target
            .closest('button')
            .getAttribute('data-scroll');
        const value = parseInt(
            e.target.closest('button').getAttribute('data-value')
        );
        document.getElementById(scrollToElement).scrollIntoView({
            behavior: 'smooth'
        });

        setActiveTab(value);
    };

    const setStickyForm = () => {
        if (detectMob() === true) {
            return;
        }

        const tab1Top = elDistanceToTop('reviews') - 60;
        const tab2Top = elDistanceToTop('mentors') - 60;
        const tab3Top = elDistanceToTop('faq') - 60;
        const comparisonSectionTop = elDistanceToTop('kids-develop') - 600;
        const footerSectionTop = elDistanceToTop('footer') - 550;

        if (window.pageYOffset < tab1Top) {
            setActiveTab(0);
        }
        if (window.pageYOffset > tab1Top && window.pageYOffset <= tab2Top) {
            setActiveTab(1);
        }
        if (window.pageYOffset > tab2Top && window.pageYOffset <= tab3Top) {
            setActiveTab(2);
        }
        if (window.pageYOffset > tab3Top) {
            setActiveTab(3);
        }

        if (stickyForm.current) {
            if (window.pageYOffset > 510) {
                stickyForm.current.classList.remove('above-footer');
                stickyForm.current.classList.add('sticky');
                stickyForm.current.style.top = '70px';
            } else {
                stickyForm.current.classList.remove('above-footer');
                stickyForm.current.classList.remove('sticky');
            }
            if (window.pageYOffset > footerSectionTop) {
                stickyForm.current.classList.remove('sticky');
                stickyForm.current.classList.add('above-footer');
                stickyForm.current.style.top = `${footerSectionTop}px`;
            }
        }
    };

    const coursePageAPICall = () => {
        setShowLoader(true);
        axios
            .get(
                `${API_URL}get-courses-by-description/?courseDescription=${courseDescription}`
            )
            .then((res) => {
                if (res.data.status === 1 && res.data.data.length) {
                    setCoursePageResponse(res.data.data);
                    setShowLoader(false);
                } else {
                    history.push('/');
                }
            })
            .catch((e) => {
                console.log(`e : ${e}`);
                history.push('/');
            });
    };

    const toggleDialog = () => {
        if (detectMob()) {
            setOpen(!open);
        } else {
            debugger;
            document
                .querySelectorAll('.input-text,.MuiOutlinedInput-input')[0]
                .focus();
        }
        setCookie('isBrochureEnquiry', true, { path: currentURL });
    };

    const handleDownload = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const dataObj = {};
        for (var [key, value] of data.entries()) {
            dataObj[key] = value;
        }
        dataObj.brouchureLink = true;
        axios.post(MAIL_URL, dataObj).catch((err) => {
            console.log(err);
        });
        setAlertOpen(true);
        setOpenDownloadDialog(false);
    };

    useEffect(() => {
        if (!detectMob()) {
            window.addEventListener('scroll', setStickyForm);
        }
        if (!countryState) {
            return;
        }
        if (
            coursePageResponse &&
            courseDescription !== coursePageResponse[0].productInfo.description
        ) {
            coursePageAPICall();
        }
        if (countryState.code.length && !coursePageResponse) {
            coursePageAPICall();
        } else if (countryState.code.length && coursePageResponse.length) {
            setCoursePageData();
        }
    }, [countryState, coursePageResponse, history.location.pathname]);

    return (
        <CookiesProvider>
            <Grid container className='course-page' onScroll={setStickyForm}>
                {!coursePageResponse || showLoader ? <Loader /> : null}

                <Helmet>
                    <meta charSet='utf-8' />
                    <title>
                        {coursePageResponse
                            ? coursePageResponse[0].seo.title
                            : ''}
                    </title>
                    <meta
                        name='description'
                        content={
                            coursePageResponse
                                ? coursePageResponse[0].seo.metaDescription
                                : ''
                        }
                    />
                </Helmet>

                <Collapse in={alertOpen} className='full-width alert-collapse'>
                    <Alert
                        severity='success'
                        onClose={() => {
                            setAlertOpen(false);
                        }}
                    >
                        Thank you for enquiring, our customer care
                        representative will get back to you soon
                    </Alert>
                </Collapse>

                <HeroSection
                    title={titleSubtitleTextData.title}
                    subtitle={titleSubtitleTextData.subtitle}
                    ratingsReviewsData={ratingsReviewsData}
                ></HeroSection>

                <Hidden xsUp>
                    <HighlightsSection
                        productBenifitsData={productBenifitsData}
                    />
                </Hidden>

                <Hidden smDown>
                    <Grid container className='course-grid-row tab-container'>
                        <Box className='max-width'>
                            <Grid container>
                                <Grid item md={8}>
                                    <Button
                                        onClick={handleTabClick}
                                        data-value='0'
                                        data-scroll='syllabus'
                                        label='Syllabus'
                                        className='fixed-menu-button'
                                    >
                                        Syllabus
                                    </Button>
                                    <Button
                                        onClick={handleTabClick}
                                        data-value='2'
                                        data-scroll='reviews'
                                        label='Reviews'
                                        className='fixed-menu-button'
                                    >
                                        Reviews
                                    </Button>
                                    <Button
                                        onClick={handleTabClick}
                                        data-value='2'
                                        data-scroll='mentors'
                                        label='Mentors'
                                        className='fixed-menu-button'
                                    >
                                        Mentors
                                    </Button>
                                    <Button
                                        onClick={handleTabClick}
                                        data-value='0'
                                        data-scroll='syllabus'
                                        label='Syllabus'
                                        className='fixed-menu-button'
                                    >
                                        FAQs
                                    </Button>
                                    <Button
                                        onClick={handleTabClick}
                                        data-value='0'
                                        data-scroll='batches'
                                        label='Batches'
                                        className='fixed-menu-button'
                                    >
                                        Batches
                                    </Button>
                                    <Button
                                        onClick={handleTabClick}
                                        data-value='0'
                                        data-scroll='payment-plans'
                                        label='Payment Plans'
                                        className='fixed-menu-button'
                                    >
                                        Payment Plans
                                    </Button>
                                    <Button
                                        onClick={handleTabClick}
                                        data-value='0'
                                        data-scroll='payment-plans'
                                        label='Payment Plans'
                                        className='fixed-menu-button'
                                    >
                                        CodingHero Advantage
                                    </Button>
                                </Grid>
                                <Grid item md={4}>
                                    <Box
                                        display={
                                            window.pageYOffset > 550
                                                ? 'block'
                                                : 'none'
                                        }
                                        width='100%'
                                        textAlign='right'
                                        alignItems='center'
                                    >
                                        <Button
                                            onClick={() => {
                                                window.open(
                                                    'https://demo.codinghero.ai/demo/book-free-demo-class'
                                                );
                                            }}
                                            data-value='0'
                                            disableFocusRipple='true'
                                            disableRipple='true'
                                            //variant="outlined"
                                            color='secondary'
                                            className='fixed-menu-button btn-primary'
                                        >
                                            Book A FREE Class
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                setOpenDownloadDialog(true)
                                            }
                                            data-value='0'
                                            data-scroll='syllabus'
                                            label='Syllabus'
                                            disableFocusRipple='true'
                                            disableRipple='true'
                                            color='secondary'
                                            className='fixed-menu-button btn-secondary-outlined'
                                        >
                                            Download Brochure
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Hidden>

                <TopRibbonSection />

                <SyllabusSection
                    toolsData={toolsData}
                    courseName={courseName}
                    effortAndDurationData={effortAndDurationData}
                    syllabusData={syllabusData}
                    stickyForm={stickyForm}
                    projectData={projectData}
                    hiddenFieldsForFormData={hiddenFieldsForFormData}
                    coursePrice={coursePrice.price}
                    currency={coursePrice.currency}
                    courseResponse={coursePageResponse}
                    setAlertOpen={setAlertOpen}
                    selectedBatch={selectedBatch}
                    setSelectedBatch={setSelectedBatch}
                    coursePlanId={coursePlanId}
                ></SyllabusSection>

                <Grid
                    container
                    className='course-grid-row batch-grid'
                    id='batches'
                >
                    <UpcomingBatches
                        batchData={batchData}
                        stickyForm={stickyForm}
                        hiddenFieldsForFormData={hiddenFieldsForFormData}
                        coursePrice={coursePrice.price}
                        currency={coursePrice.currency}
                        courseResponse={coursePageResponse}
                        setAlertOpen={setAlertOpen}
                        setCookie={setCookie}
                        selectedBatch={selectedBatch}
                        setSelectedBatch={setSelectedBatch}
                        coursePlanId={coursePlanId}
                        setCoursePlanId={setCoursePlanId}
                    ></UpcomingBatches>
                </Grid>

                <Box
                    className='course-grid-row full-width section-light-grey-bg'
                    id='reviews'
                >
                    <ReviewsSection reviewsData={reviewsData} />
                </Box>

                <Box
                    className='course-grid-row section-light-grey-bg full-width'
                    id='payment-plans'
                >
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <PaymentSection
                                paymentPlans={paymentPlans}
                                price={coursePrice}
                            ></PaymentSection>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    className='course-grid-row ch-experience full-width'
                    id='ch-experience'
                >
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Box p={3}>
                                        <Typography
                                            variant={'h2'}
                                            className='subtitle full-width text-left'
                                        >
                                            Flaunt Your Certificate
                                        </Typography>
                                    </Box>
                                    <Box p={3}>
                                        <Typography variant={'body1'}>
                                            Get a <b>Universally Verifiable </b>{' '}
                                            and <b>Tamper Proof</b> certificate
                                            for {courseName} Course!
                                        </Typography>
                                    </Box>
                                    <Hidden smDown>
                                        <img
                                            src='/images/course-page/arrow-curved.png'
                                            alt='CodingHero'
                                            className='certificate-arrow'
                                        />
                                    </Hidden>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    <Grid
                                        container
                                        className='ch-certificate-img-container course-grid-content'
                                    ></Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    className='course-grid-row ch-experience full-width'
                    id='mentors'
                >
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <Faqs
                                courseName={courseName}
                                sectionTitle={
                                    'FAQs About ' + courseName + ' Course'
                                }
                                faqOptions={faqOptionsData}
                                faqData={faqData}
                                value={0}
                            ></Faqs>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    className='course-grid-row ch-experience full-width'
                    id='ch-experience'
                >
                    <Box className='max-width'>
                        <Grid container>
                            <Grid item xs={12} md={9}>
                                <Grid container className='course-grid-row'>
                                    <Typography
                                        variant={'h2'}
                                        className='subtitle full-width text-left'
                                    >
                                        The CodingHero Experience
                                    </Typography>
                                </Grid>
                                <Grid
                                    container
                                    className='ch-experience-img-container course-grid-content'
                                ></Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>

                <Box
                    className='course-grid-row ch-experience full-width'
                    id='mentors'
                >
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <Faqs
                                courseName={courseName}
                                sectionTitle={
                                    courseName + ' Training Program Details'
                                }
                                faqOptions={faqOptionsData}
                                faqData={courseQuestions}
                                value={0}
                            ></Faqs>
                        </Grid>
                    </Grid>
                </Box>

                <Hidden xsUp>
                    <Grid
                        container
                        className='course-grid-row popular-courses-row'
                        id='popular-courses'
                    >
                        <Grid item sm={1} md={1}></Grid>
                        <Grid item sm={10} md={7}>
                            <Grid container className='section-title-container'>
                                <Typography variant={'h2'} className='subtitle'>
                                    Popular Courses
                                </Typography>
                            </Grid>
                            <Grid container>
                                <Hidden xsDown>
                                    <Carousel navButtonsAlwaysVisible={true}>
                                        <Grid container spacing={1}>
                                            <CourseCard></CourseCard>
                                            <CourseCard></CourseCard>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <CourseCard></CourseCard>
                                            <CourseCard></CourseCard>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <CourseCard></CourseCard>
                                            <CourseCard></CourseCard>
                                        </Grid>
                                    </Carousel>
                                </Hidden>
                                <Hidden smUp>
                                    <Carousel navButtonsAlwaysVisible={true}>
                                        <Grid container spacing={1}>
                                            <CourseCard></CourseCard>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <CourseCard></CourseCard>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <CourseCard></CourseCard>
                                        </Grid>
                                    </Carousel>
                                </Hidden>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>

                <Box
                    className='course-grid-row full-width'
                    id='kids-develop'
                    mb={5}
                >
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <KidsDevelopSectionSmall />
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    className='course-grid-row ch-experience full-width'
                    id='mentors'
                >
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <MentorsSection
                                mentorsData={mentorsData}
                            ></MentorsSection>
                        </Grid>
                    </Grid>
                </Box>

                <Box className='course-grid-row full-width' id='mentors'>
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <StudiesSection />
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    className='course-grid-row full-width section-light-grey-bg'
                    id='comparison-section'
                >
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <CourseComparisonSection
                                coursePrice={coursePrice.price}
                                currency={coursePrice.currency}
                            ></CourseComparisonSection>
                        </Grid>
                    </Grid>
                </Box>

                <Box
                    className='course-grid-row full-width section-light-grey-bg'
                    id='about-ch'
                >
                    <Grid container className='max-width'>
                        <Grid item xs={12} md={9}>
                            <AboutSection></AboutSection>
                        </Grid>
                    </Grid>
                </Box>

                <MobileFooter
                    stickyForm={stickyForm}
                    hiddenFieldsForFormData={hiddenFieldsForFormData}
                    coursePrice={coursePrice.price}
                    currency={coursePrice.currency}
                    courseResponse={coursePageResponse}
                    setAlertOpen={setAlertOpen}
                    selectedBatch={selectedBatch}
                    setSelectedBatch={setSelectedBatch}
                    coursePlanId={coursePlanId}
                ></MobileFooter>

                <EnquiryFormMob
                    open={open}
                    toggleDialog={toggleDialog}
                    stickyForm={stickyForm}
                    hiddenFieldsForFormData={hiddenFieldsForFormData}
                    coursePrice={coursePrice.price}
                    currency={coursePrice.currency}
                    courseResponse={coursePageResponse}
                    setAlertOpen={setAlertOpen}
                    selectedBatch={selectedBatch}
                    setSelectedBatch={setSelectedBatch}
                    coursePlanId={coursePlanId}
                ></EnquiryFormMob>

                <Dialog
                    className='download-brochure-form'
                    open={openDownloadDialog}
                    onClose={() => {
                        setOpenDownloadDialog(false);
                    }}
                >
                    <Grid
                        justify={'center'}
                        container
                        className='download-form grid-row'
                    >
                        <CancelIcon
                            className='cancel-round-icon'
                            onClick={() => setOpenDownloadDialog(false)}
                        />
                        <Grid item sm={8}>
                            <Typography
                                variant={'h2'}
                                className='subtitle large full-width text-center'
                            >
                                Download Brochure For This Program
                            </Typography>
                            <form
                                method='POST'
                                className='full-width'
                                onSubmit={handleDownload}
                            >
                                <FormControl className='full-width'>
                                    <Grid container>
                                        <TextField
                                            required
                                            className='input-text full-width'
                                            label='Name'
                                            name='name'
                                        />
                                        <TextField
                                            required
                                            className='input-text full-width'
                                            label='Email'
                                            type={'email'}
                                            name='email'
                                        />
                                    </Grid>
                                    <Grid container>
                                        <Autocomplete
                                            className='full-width'
                                            options={COUNTRIES_WITH_CODES}
                                            value={countryCode}
                                            onChange={(event, newValue) => {
                                                setCountryCode(newValue);
                                            }}
                                            name='code'
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                                option.label
                                            }
                                            renderOption={(option) => (
                                                <React.Fragment>
                                                    {option.phone}{' '}
                                                    {option.label} (
                                                    {option.code})
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label='Choose a country'
                                                    className='country-select-border'
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete:
                                                            'new-password' // disable autocomplete and autofill
                                                    }}
                                                />
                                            )}
                                        />
                                        <TextField
                                            required
                                            className='input-text full-width'
                                            label='Phone'
                                            name='phone'
                                        />
                                    </Grid>
                                    <Grid container>
                                        <TextField
                                            required
                                            className='input-text full-width'
                                            label='City'
                                            name='geolocation'
                                        />
                                    </Grid>
                                    <Grid container>
                                        <Grid item className='radio-text'>
                                            <Radio
                                                required
                                                name='checkbox_authorize'
                                                color={'primary'}
                                            />
                                            <Typography
                                                className='regular-text'
                                                display={'inline'}
                                            >
                                                I agree to CodingHero’s{' '}
                                                <Link to='/privacy-policy'>
                                                    privacy policy
                                                </Link>{' '}
                                                and authorize CodingHero
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container className='grid-row'>
                                        <Button
                                            color='primary'
                                            className='enquire-now-btn'
                                            type='submit'
                                            variant='outlined'
                                        >
                                            ENQUIRE NOW
                                        </Button>
                                    </Grid>
                                </FormControl>
                            </form>
                        </Grid>
                    </Grid>
                </Dialog>
            </Grid>
        </CookiesProvider>
    );
};

export default CoursePage;
