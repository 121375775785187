import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import {
    Grid,
    TextField,
    Radio,
    Typography,
    Button,
    FormControl
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    getCurrencySymbol,
    MAIL_URL,
    COUNTRIES_WITH_CODES
} from '../../../../constants/constants';

import { useCookies } from 'react-cookie';

import './EnquiryFormDesk.scss';

const EnquiryFormDesk = ({
    stickyForm,
    hiddenFieldsForFormData,
    currency,
    coursePrice,
    courseResponse,
    setAlertOpen,
    selectedBatch,
    coursePlanId
}) => {
    const [state, setState] = useState({
        name: '',
        email: '',
        geolocation: '',
        code: '',
        phone: ''
    });
    const [countryCode, setCountryCode] = useState();
    const currencySymbol = getCurrencySymbol(currency);
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['isBrochureEnquiry']);

    const handleInputChange = (event, newValue) => {
        let { name, value } = event.target;
        if (name === 'code') {
            value = newValue;
        }
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleEnquiryFormSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const dataObj = {};
        for (var [key, value] of data.entries()) {
            dataObj[key] = value;
        }
        dataObj.brouchureLink = cookies['isBrochureEnquiry'];
        axios.post(MAIL_URL, dataObj).catch((err) => {
            console.log(err);
        });

        setAlertOpen(true);
        window.gtag_report_conversion(window.location.href);
        const currentPath = history.location.pathname;
        const enrolUrl =
            currentPath[currentPath.length - 1] == '/'
                ? currentPath + 'enroll'
                : currentPath + '/enroll';
        history.push({
            pathname: enrolUrl,
            state: {
                detail: courseResponse,
                userDetails: state,
                selectedBatch: selectedBatch,
                coursePlanId: coursePlanId
            }
        });
    };

    return (
        <Grid container className='interested-form' ref={stickyForm}>
            <Typography variant={'h2'} className='subtitle'>
                Interested In This Program
            </Typography>
            <form onSubmit={handleEnquiryFormSubmit}>
                <FormControl>
                    <input
                        value={hiddenFieldsForFormData.course_id || ''}
                        name='course_id'
                        type='hidden'
                    />
                    <input
                        value={hiddenFieldsForFormData.product_category || ''}
                        name='product_category'
                        type='hidden'
                    />
                    <input
                        value={hiddenFieldsForFormData.product_group || ''}
                        name='product_group'
                        type='hidden'
                    />
                    <input
                        value={hiddenFieldsForFormData.product || ''}
                        name='product'
                        type='hidden'
                    />
                    <input
                        value={hiddenFieldsForFormData.mode_of_training || ''}
                        name='mode_of_training'
                        type='hidden'
                    />
                    <input
                        value={hiddenFieldsForFormData.brochure || ''}
                        name='brochure_link'
                        type='hidden'
                    />
                    <TextField
                        required
                        //variant='outlined'
                        className='input-text'
                        label='Name'
                        name='name'
                        onChange={handleInputChange}
                    />
                    <TextField
                        required
                        //variant='outlined'
                        className='input-text'
                        label='Email'
                        type={'email'}
                        name='email'
                        onChange={handleInputChange}
                    />
                    <Autocomplete
                        options={COUNTRIES_WITH_CODES}
                        value={countryCode}
                        onChange={(event, newValue) => {
                            event.target.name = 'code';
                            handleInputChange(event, newValue.phone);
                            setCountryCode(newValue);
                        }}
                        name='code'
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.phone} {option.label} ({option.code})
                            </React.Fragment>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label='Choose a country'
                                className='country-select-border'
                                //variant='outlined'
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <TextField
                        required
                        //variant='outlined'
                        className='input-text'
                        label='Phone'
                        name='phone'
                        onChange={handleInputChange}
                    />
                    <TextField
                        required
                        //variant='outlined'
                        className='input-text'
                        label='City'
                        name='geolocation'
                        onChange={handleInputChange}
                    />
                    <Grid container>
                        <Grid item sm={1}>
                            <Radio
                                required
                                name='checkbox_authorize'
                                color={'primary'}
                                value='checked'
                            />
                        </Grid>
                        <Grid item sm={11} className='radio-text'>
                            <Typography
                                className='regular-text'
                                display={'inline'}
                            >
                                I agree to CodingHero’s{' '}
                                <Link to='/privacy-policy'>privacy policy</Link>{' '}
                                and authorize CodingHero
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className='course-grid-row'>
                        <Typography
                            variant={'h4'}
                            className='heading-level-4 large'
                            display='inline'
                        >
                            {`${currencySymbol} ${coursePrice.toLocaleString()}`}
                        </Typography>
                        <Typography className='regular-text padding-left'>
                            (All inclusive)
                        </Typography>
                    </Grid>
                    <Grid container className='course-grid-row'>
                        <Button
                            variant='outlined'
                            color='primary'
                            className='enquire-now-btn'
                            type='submit'
                        >
                            ENQUIRE NOW
                        </Button>
                    </Grid>
                </FormControl>
            </form>
        </Grid>
    );
};

export default EnquiryFormDesk;
