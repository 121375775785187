import React from 'react';

import { Grid, Hidden, Typography, Box } from '@material-ui/core';

import { isObjEmpty } from '../../../../constants/constants';

import './HighlightsSection.scss';

const HighlightsSection = ({ productBenifitsData }) => {
    if (isObjEmpty(productBenifitsData)) {
        return null;
    }

    return (
        <Box className='full-width'>
            <Grid container className='course-grid-row highlights'>
                <Grid item sm={12} md={6}>
                    <Grid container spacing={0}>
                        <Hidden xsDown>
                            <Grid item sm={1} md={2}></Grid>
                        </Hidden>
                        {productBenifitsData.live_mentoring ? (
                            <Grid item sm={2} xs={4} className='highlight-item'>
                                <Grid container className='highlight-content'>
                                    <Typography className='highlight-text-large'>
                                        {productBenifitsData.live_mentoring}+
                                    </Typography>
                                    <Typography className='highlight-text-small'>
                                        Live Mentoring
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : null}
                        {productBenifitsData.coding_assignments ? (
                            <Grid item sm={2} xs={4} className='highlight-item'>
                                <Grid container className='highlight-content'>
                                    <Typography className='highlight-text-large'>
                                        {productBenifitsData.coding_assignments}+
                                    </Typography>
                                    <Typography className='highlight-text-small'>
                                        Coding Assignments
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : null}
                        {productBenifitsData.real_time_projects ? (
                            <Grid item sm={2} xs={4} className='highlight-item'>
                                <Grid container className='highlight-content'>
                                    <Typography className='highlight-text-large'>
                                        {productBenifitsData.real_time_projects}+
                                    </Typography>
                                    <Typography className='highlight-text-small'>
                                        Real-Life Projects
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : null}
                        {productBenifitsData.industry_cases ? (
                            <Grid item sm={2} xs={4} className='highlight-item'>
                                <Grid container className='highlight-content'>
                                    <Typography className='highlight-text-large'>
                                        {productBenifitsData.industry_cases}+
                                    </Typography>
                                    <Typography className='highlight-text-small'>
                                        Industry Cases
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : null}
                        {productBenifitsData.coding_support &&
                        productBenifitsData.coding_support ? (
                            <Grid item sm={2} xs={4} className='highlight-item'>
                                <Grid container className='highlight-content'>
                                    <Typography className='highlight-text-large'>
                                        {productBenifitsData.coding_support}
                                    </Typography>
                                    <Typography className='highlight-text-small'>
                                        Coding Support
                                    </Typography>
                                </Grid>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
                <Grid item></Grid>
            </Grid>
        </Box>
    );
};

export default HighlightsSection;
