// import installed dependencies
import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// import custom contexts
import {
    CountryContext,
    CountryContextProvider
} from './contexts/country/CountryContext';

// import pages
import HomePage from './pages/homepage/HomePage';
import CategoryPage from './pages/category/CategoryPage';
import CoursePage from './pages/course/CoursePage';
import PrivacyPage from './pages/privacy/PrivacyPage';
import PaymentPage from './pages/payment/PaymentPage';

// import components
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Breadcrumbs from './components/breadcrumbs/Breadcrumbs';

export default function App() {
    const [countryState, setCountryState] = useContext(CountryContext);
    return (
        <Router>
            <CountryContextProvider value={[countryState, setCountryState]}>
                <div className='App'>
                    <Header />
                    <Breadcrumbs />
                    <Switch>
                        <Route exact path='/'>
                            <HomePage />
                        </Route>
                        {/* <Route exact path='/courses'>
                            <CategoryPage />
                        </Route> */}
                        <Route exact path='/courses/:courseCategory'>
                            <CategoryPage />
                        </Route>
                        <Route
                            exact
                            path='/courses/:courseCategory/:courseName'
                        >
                            <CoursePage />
                        </Route>
                        <Route
                            exact
                            path='/courses/:courseCategory/:courseName/enroll'
                        >
                            <PaymentPage />
                        </Route>
                        <Route exact path='/privacy-policy'>
                            <PrivacyPage />
                        </Route>
                    </Switch>
                    <Footer />
                </div>
            </CountryContextProvider>
        </Router>
    );
}
