import React from 'react';

import AccordianContainer from '../../../../components/acordian-container/AccordianContainer';

import { ProjectCard } from '../../../../components/card/Card';
import { EnquiryFormDesk } from '../CoursePageSections';

import { Grid, Typography, Hidden } from '@material-ui/core';

import { isObjEmpty } from '../../../../constants/constants';
import DateRangeTwoToneIcon from '@material-ui/icons/DateRangeTwoTone';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

import './SyllabusSection.scss';
import { CalendarTodayTwoTone } from '@material-ui/icons';
import ReactWordcloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';

const SyllabusSection = ({
    toolsData,
    courseName,
    effortAndDurationData,
    syllabusData,
    stickyForm,
    projectData,
    hiddenFieldsForFormData,
    coursePrice,
    currency,
    courseResponse,
    setAlertOpen,
    selectedBatch,
    setSelectedBatch,
    coursePlanId
}) => {
    let words = []

    toolsData.map((tool, i) => {
        words.push({text: tool.text, value: Math.floor((Math.random() * 100) + 1)})
    })

    const toolItems = toolsData.map((tool, i) => {

        return (
            <Grid
                item
                key={`tool-item-${i}`}
                className='text-center text-center-syllabus-icon-box'
                xs={12}
                sm={'auto'}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <img alt='' height='40px' width='40px' src={tool.img} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className='regular-text'>
                            {tool.text}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        );
    });
    return (
        <Grid container className='syllabus' id='syllabus'>
            <Grid container className='course-grid-row max-width'>
                <Grid item md={9} xs={12} className='syllabus-container'>
                    <Grid container className='course-grid-row'>
                        <Grid item md={12} xs={12}>
                            <Typography
                                variant={'h2'}
                                className='subtitle syllabus-title full-width text-left'
                            >
                                Syllabus For {courseName} Course
                            </Typography>
                        </Grid>
                    </Grid>
                    {toolsData.length ? (
                        <Grid
                            container
                            className='tools-strip course-grid-content'
                        >
                            
                            <Grid item xs={12} md={12}>
                                {/* <Grid container justify={'center'}>
                                    <Typography
                                        variant={'h2'}
                                        className='subtitle medium'
                                    >
                                        Tools You'll Learn
                                    </Typography>
                                </Grid> */}
                                
                                
                                <Grid container className='course-grid-row'>
                                    <Grid item xs={12} sm={8} md={9}>
                                        <Grid container justify={'center'} className="word-cloud-container">
                                            <ReactWordcloud options={{
                                                rotations: 1,
                                                rotationAngles: [0, 0],
                                                }} words={words} />
                                        </Grid>
                                    </Grid>
                                    {!isObjEmpty(effortAndDurationData) ? (
                                        <Grid item xs={12} sm={4} md={3}>
                                            <Grid container>
                                                {effortAndDurationData.duration ? (
                                                    <Grid item sm={6} className='course-duration'>
                                                        <DateRangeTwoToneIcon></DateRangeTwoToneIcon>
                                                        <Grid
                                                            container
                                                            justify={'center'}
                                                        >
                                                            <Typography
                                                                variant={
                                                                    'caption'
                                                                }
                                                                className='caption'
                                                            >
                                                                {
                                                                    effortAndDurationData.duration
                                                                }{' '}
                                                                Weeks
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            justify={'center'}
                                                        >
                                                            <Typography
                                                                display={
                                                                    'inline'
                                                                }
                                                                className='regular-text'
                                                            >
                                                                Total Duration
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ) : null}
                                                {effortAndDurationData.effort ? (
                                                    <Grid item sm={6} className='course-duration'>
                                                        <AccessAlarmIcon></AccessAlarmIcon>
                                                        <Grid
                                                            container
                                                            justify={'center'}
                                                        >
                                                            <Typography
                                                                variant={
                                                                    'caption'
                                                                }
                                                                className='caption'
                                                            >
                                                                {
                                                                    effortAndDurationData.effort
                                                                }{' '}
                                                                Hrs/Week
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            container
                                                            justify={'center'}
                                                        >
                                                            <Typography
                                                                display={
                                                                    'inline'
                                                                }
                                                                className='regular-text'
                                                            >
                                                                Effort
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                ) : null}
                                            </Grid>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </Grid>
                            <Hidden smDown>
                                <Grid item sm={3}>
                                    <EnquiryFormDesk
                                        stickyForm={stickyForm}
                                        hiddenFieldsForFormData={
                                            hiddenFieldsForFormData
                                        }
                                        coursePlanId={coursePlanId}
                                        coursePrice={coursePrice}
                                        currency={currency}
                                        courseResponse={courseResponse}
                                        setAlertOpen={setAlertOpen}
                                        selectedBatch={selectedBatch}
                                        setSelectedBatch={setSelectedBatch}
                                    />
                                </Grid>
                            </Hidden>
                        </Grid>
                    ) : null}

                    <AccordianContainer
                        accordianData={syllabusData}
                        dot={true}
                    />
                </Grid>
                <Grid item sm></Grid>
            </Grid>
            {projectData.length ? (
                <Grid container className='course-grid-row'>
                    <Grid container className='course-grid-row'>
                        <Grid item sm={1}></Grid>
                        <Grid item xs={12} sm={7}>
                            <Typography variant={'h2'} className='subtitle'>
                                Capstone Domain Projects
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item sm={1}></Grid>
                        <Grid item xs={12} sm={7}>
                            <Grid container>
                                <ProjectCard></ProjectCard>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    );
};

export default SyllabusSection;
