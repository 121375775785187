import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import { MentorCard } from '../../../../components/card/Card';

const MentorsSection = ({ mentorsData }) => {
    if (!mentorsData || !mentorsData.length) {
        return null;
    }

    return (
        <Box justify={'flex-start'}>
            <Grid container justify={'flex-start'}>
                <Grid item xs={12} md={12} className='course-grid-row text-center'>
                    <Box pb={2}>
                        <Typography
                            variant={'h2'}
                            className='subtitle full-width '
                        >
                            Meet Our Mentors
                        </Typography>
                    </Box>
                    <Typography className='full-width text-left'>
                        All our mentors go through stringent selection and
                        onboarding process. You can be sure your child is in
                        safe hands.
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className='course-grid-content' justify={'center'}>
                <Grid item xs={12} md={12}>
                    <Grid container justify={'center'}>
                        <MentorCard mentorsData={mentorsData}></MentorCard>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};
export default  MentorsSection;
