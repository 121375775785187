import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { courses } from '../../../config/courses/AllCourses';
import { Grid, Typography, Box } from '@material-ui/core';
import './HomeCourseCard.scss';
import {
    defaultCountry,
    indianCurrency,
    usCurrency
} from '../../../constants/constants';
import { CountryContext } from '../../../contexts/country/CountryContext';

const getPrice = async (props, countryState) => {
    let countryValue = countryState.code;
    const symbol =
        countryValue === defaultCountry ? indianCurrency : usCurrency;
    if (countryValue !== 'IN' && countryValue !== 'US') {
        countryValue = "ROW";
    }
    const markdownFile = await import(
        `../../../config/courses/${props}/${countryValue}/online/${props}.json`
    );
    const total = symbol + ' ' + markdownFile.pricing.mrp;
    return total;
};

const HomeCardArray = (props) => {
    const item = courses[props.index];
    const [price, setPrice] = useState();
    const [countryState, setCountryState] = useContext(CountryContext);
    if (!countryState) {
        return null;
    } else {
        getPrice(item.description, countryState)
            .then((result) => {
                setPrice(result);
            })
            .catch((e) => {
                console.log(e);
            });
    }
    return (
        <Link to={`/courses/data/${item.description}`} className='simple-link'>
            <Grid>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography className='course-name-section'>
                            {item.name}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} className='course-fee'>
                        <Typography className='text-bold'>{price}</Typography>
                        <Typography className='regular-text small text-bold'>
                            Per month
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container className='course-ribbon-section'>
                    <Grid item md={12}>
                        <Typography>{item.data1}</Typography>
                        <Typography>{item.data2}</Typography>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid className='ribbon'>
                        <Typography
                            variant={'caption'}
                            display={'inline'}
                            className='ribbon3'
                        >
                            2 sessions per week
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Link>
    );
};

const HomeCourseCard = ({}) => {
    return (
        <>
            <Grid container className='home-course-card'>
                <Grid item xs={12} md={12}>
                    <Grid>
                        <Typography variant={'h2'} className='header'>
                            Our Courses
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box p={2} className='outer-box'>
                        <HomeCardArray index='0'></HomeCardArray>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box p={2} className='outer-box'>
                        <HomeCardArray index='1'></HomeCardArray>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box p={2} className='outer-box'>
                        <HomeCardArray index='2'></HomeCardArray>
                    </Box>
                </Grid>
            </Grid>{' '}
        </>
    );
};

export default HomeCourseCard;
