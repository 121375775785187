import React from 'react';

import { Grid, Typography, Hidden } from '@material-ui/core';

import { CourseCard as Card } from '../../components/card/Card';
import TrainingFiter from '../../components/training-filter/TrainingFilter';

import {
    CategoryPanelLeft,
    CategoryPanelTop
} from '../../components/category-panel/CategoryPanel';

import { panelMenu, filters, courseDetails } from '../../mock/category-page';

import './CategoryPage.scss';

const CategoryPage = () => {
    const cardArray = courseDetails.map((item, i) => {
        return <Card courseDetail={item} key={`card-key-${i}`}></Card>;
    });
    return (
        <Grid container className='category-page'>
            <Grid item xs={12} className='hero'>
                <Typography className='hero-title'>Get Started</Typography>
                <Typography className='hero-subtitle'>
                    Choose a course and propel your career in a new exciting
                    direction
                </Typography>
            </Grid>

            <Grid container className='category-page-main-content'>
                <Grid container>
                    <Grid item xs sm={2} md={4} lg></Grid>
                    <Grid item xs={6} sm={4} md={4} lg>
                        <Hidden mdUp>
                            <CategoryPanelTop
                                panelMenu={panelMenu}
                            ></CategoryPanelTop>
                        </Hidden>
                    </Grid>
                    <Grid item xs={6} sm={4} md={4} lg={2}>
                        <TrainingFiter filters={filters}></TrainingFiter>
                    </Grid>
                    <Hidden mdUp>
                        <Grid item sm={2}></Grid>
                    </Hidden>
                </Grid>
                <Grid container className='category-count-container'>
                    <Grid item xs></Grid>
                    <Grid item md={9} lg={9} xs={12}>
                        <Typography
                            variant={'h3'}
                            display={'inline'}
                            className='section-subtitle'
                        >
                            48 Course available
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={0} className='category-container'>
                    <Grid item md={3} className='left-panel-container'>
                        <Hidden smDown>
                            <CategoryPanelLeft
                                panelMenu={panelMenu}
                            ></CategoryPanelLeft>
                        </Hidden>
                    </Grid>
                    <Grid item md></Grid>
                    <Grid item sm={12} md={9}>
                        <Grid container className='cards-wrapper' spacing={1}>
                            {cardArray}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CategoryPage;
