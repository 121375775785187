import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
    List,
    ListItem,
    ListItemAvatar,
    ListItemText
} from '@material-ui/core';
import './ListSection.scss';

import { getCurrencySymbol } from '../../../../constants/constants';

const ListSection = ({ courseName, totalAmount, currency }) => {
    const listData = [
        {
            data: '700 hours of Live Classroom',
            imageSrc: 'https://acadgild.com/images/icon1.jpg'
        },
        {
            data: '200 Assignments',
            imageSrc: 'https://acadgild.com/images/icon2.jpg'
        },
        {
            data: '3 Industry Cases',
            imageSrc: 'https://acadgild.com/images/icon3.jpg'
        },
        {
            data: '5 Real Time Projects',
            imageSrc: 'https://acadgild.com/images/icon4.jpg'
        },
        {
            data: 'Lifetime Dashboard Access',
            imageSrc: 'https://acadgild.com/images/icon5.jpg'
        }
    ];
    return (
        <Grid container className='grid-row course-info'>
            <Typography variant={'h3'} className='section-subtitle large'>
                Enroll for {courseName} course to get:{' '}
            </Typography>
            <List className='course-details'>
                {listData.map((value, index) => (
                    <ListItem key={index}>
                        <ListItemAvatar>
                            <img alt='' src={value.imageSrc} />
                        </ListItemAvatar>
                        <ListItemText primary={value.data} />
                    </ListItem>
                ))}
            </List>
            <Grid className='coursboxfree'>
                <Typography variant={'h3'} className='section-subtitle medium'>
                    Course Free{' '}
                </Typography>
                <Typography>
                    {getCurrencySymbol(currency) + totalAmount.toLocaleString()}
                </Typography>
            </Grid>
            <Grid className='contact-info'>
                <Typography className='regular-text medium'>
                    Call us if you are facing any issues
                </Typography>
                <Grid className='d-flex-center'>
                    <img alt='' src='https://acadgild.com/images/callp.jpg' />
                    <Typography className='regular-text xl text-bold'>
                        (+91) 888 002 5025
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                className='course-guarantee d-flex-center text-center'
            >
                <img alt='' src='https://acadgild.com/images/rup.jpg' />
                <Typography className='regular-text small text-bold'>
                    100% Money Back Guarantee
                </Typography>
            </Grid>
        </Grid>
    );
};
export default ListSection;
