import React from 'react';

import { Grid, Typography, Box } from '@material-ui/core';

import './HomeCard.scss';

const HomeCard = ({}) => {
    return (
        <>
            <Grid container className='home-card'>
                <Grid item xs={12} sm={4} md={3}>
                    <Grid item xs={12} md={10} className='m-auto'>
                        <Box p={2} className='outer-box'>
                            <Grid container className='justify-content-center'>
                                <Grid container className='mt-5'>
                                    <img
                                        className='img-fluid'
                                        src='/images/coding-hero/home/broadcast.png'
                                        alt=''
                                    />
                                </Grid>
                                <Grid container className='mt-5'>
                                    <Typography className='regular-text xl text-bold'>
                                        Live Online
                                        <br />
                                        Sessions
                                    </Typography>
                                </Grid>
                                <Grid container className='mt-3'>
                                    <Typography className='regular-text-medium'>
                                        Your kid will learn cool new
                                        technologies from the safety of your
                                        home
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Grid item xs={12} md={10} className='m-auto'>
                        <Box
                            p={2}
                            className='outer-box theme-bg-color box-shadow'
                        >
                            <Grid container className='justify-content-center'>
                                <Grid container className='mt-5'>
                                    <img
                                        className='img-fluid'
                                        src='/images/coding-hero/home/teacher.png'
                                        alt=''
                                    />
                                </Grid>
                                <Grid container className='mt-5'>
                                    <Typography className='regular-text xl text-bold'>
                                        Expert Friendly
                                        <br />
                                        Mentors
                                    </Typography>
                                </Grid>
                                <Grid container className='mt-3'>
                                    <Typography className='regular-text-medium'>
                                        Learn from the best mentors who'll make
                                        learning a fun experience
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Grid item xs={12} md={10} className='m-auto'>
                        <Box p={2} className='outer-box'>
                            <Grid container className='justify-content-center'>
                                <Grid container className='mt-5'>
                                    <img
                                        className='img-fluid'
                                        src='/images/coding-hero/home/support.png'
                                        alt=''
                                    />
                                </Grid>
                                <Grid container className='mt-5'>
                                    <Typography className='regular-text xl text-bold'>
                                        Great After-class
                                        <br />
                                        Support
                                    </Typography>
                                </Grid>
                                <Grid container className='mt-3'>
                                    <Typography className='regular-text-medium'>
                                        Stuck while doing assignments? Our
                                        expert team is available to help
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>{' '}
        </>
    );
};

export default HomeCard;
