import React from "react";
import { Grid, Typography } from "@material-ui/core";
import "./PrivacyPage.scss";

const PrivacyPage = () => {
    return (
        <Grid container className="privacy-page">
            <Grid item xs={12} className="header"></Grid>
            <Grid container className="category-page-main-content">
                <Grid container className="privacy-page-header">
                    <Grid item xs={1} sm={1}></Grid>
                    <Grid item xs={10} sm={10} md={10} lg={10}>
                        <Typography variant={"h1"} display={"inline"} className="privacy-page-header">
                            Privacy Policy
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}></Grid>
                </Grid>
                <Grid container className="privacy-page-content">
                    <Grid container>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={10} sm={3}>
                            <Typography variant={"h2"} display={"inline"} className="privacy-title-sub">
                                1.HOW WE COLLECT AND USE INFORMATION
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={7}>
                            <Typography className="p">
                                Personally Identifiable Information: We collect information that can be used to identify you as an individual (“Personally Identifiable Information”) only when you provide such information directly to us in
                                connection with the Service.
                            </Typography>
                            <Typography className="p">
                                We ask for Personally Identifiable Information such as your name and e-mail address when you register for a Member account with the Service, or if you correspond with us (in which case we will also retain our
                                responses). We may also collect demographic information, such as your age, gender, preferences, and interests. If you purchase products or services or sign up for a paid subscription, then we will also ask
                                for additional information, such as your credit card number and billing address. We may also retain any messages you send through the Service, and may collect information you provide in User Content you post
                                to the Service.
                            </Typography>
                            <Typography className="p">
                                We may receive Personally Identifiable Information about you from third parties, including, for example, information about your transactions, purchase history, or relationships with various product and
                                service providers, and your use of certain applications. For example, if you access our website or Service through a third-party connection or log-in, for example, through Facebook Connect, by “following,”
                                “liking,” adding the CodingHero application, linking your account to the CodingHero service, etc., that third party may pass certain information about your use of its service to CodingHero. This information
                                could include, but is not limited to, the user ID associated with your account, any information you have permitted the third party to share with us, and any information you have made public in connection with
                                that service. You should always review, and if necessary, adjust your privacy settings on third-party websites and services before linking or connecting them to CodingHero’s website or Service.
                            </Typography>
                            <Typography className="p">
                                We use Personally Identifiable Information to communicate directly with you. We may send you emails containing newsletters, promotions and special offers. If you do not want to receive these marketing
                                communications, you may change your preferences in your user profile settings page. We also use your Personally Identifiable Information to send you Service-related emails (e.g., account verification,
                                purchase and billing confirmations and reminders, changes/updates to features of the Service, technical and security notices). You may not opt out of Service-related e-mails.
                            </Typography>
                            <Typography className="p">
                                If you choose to use our invitation service to invite a third party to the Service, we will ask you for that person’s email address and automatically send an email invitation to the invitee. CodingHero stores
                                this information to send this email, to register the invitee if your invitation is accepted, and to track the success of our invitation service. Unless the invitee becomes a registered user of the Service,
                                CodingHero does not use the invitee’s email address for marketing purposes, and does not share the invitee’s email address with third parties other than CodingHero’s service providers or in the event of a
                                corporate transaction or proceeding as described below in Sharing of Your Information. The invitee may contact us at any time to request that we remove his or her information from our database at
                                <strong>hello@codinghero.ai.</strong>
                            </Typography>
                            <Typography className="p">
                                <Typography variant={"b"}>Non-Personally Identifiable Information:</Typography>
                                We also collect and use information about your interactions with the Service in a manner and format that does not identify you as an individual (“Non-Personally Identifiable Information”). Generally, we do
                                not combine or associate Non-Personally Identifiable Information with your Personally Identifiable Information. If we do combine Non-Personally Identifiable Information with Personally Identifiable
                                Information, we treat the combined information as Personally Identifiable Information. We may collect, use, and disclose Non-Personally Identifiable Information as set forth below.
                            </Typography>
                            <Typography className="p">
                                <b>Analytics Information: </b>
                                We use third-party analytics tools to help us measure traffic and usage trends for the Service. These tools collect information sent by your browser as part of a web page request, including the web pages you
                                visit, your browser add-ons, your browser’s width and height, and other information that assists us in improving the Service. We collect and use this analytics information in aggregate form such that it
                                cannot reasonably be manipulated to identify any particular individual user.
                            </Typography>
                            <Typography className="p">
                                <b>Cookies Information:</b>
                                When you visit the Service, we may send one or more cookies — a small text file containing a string of alphanumeric characters — to your computer that uniquely identifies your browser and lets CodingHero help
                                you log in faster and enhance your navigation through the site. A cookie may also convey information to us about how you use the Service (e.g., the pages you view, the links you click and other actions you
                                take on the Service), and allow us or our business partners to track your usage of the Service over time. A persistent cookie remains on your hard drive after you close your browser. Persistent cookies may be
                                used by your browser on subsequent visits to the site. Persistent cookies can be removed by following your web browser’s directions. A session cookie is temporary and disappears after you close your browser.
                                You can reset your web browser to refuse all cookies or to indicate when a cookie is being sent. However, some features of the Service may not function properly if the ability to accept cookies is disabled.
                            </Typography>
                            <Typography className="p">
                                <b>Log File Information: </b>
                                Log file information is automatically reported by your browser each time you access a web page. When you use our Service, our servers automatically record certain log file information. These server logs may
                                include anonymous information such as your web request, Internet Protocol (“IP”) address, browser type, referring / exit pages and URLs, number of clicks and how you interact with links on the Service, domain
                                names, landing pages, pages viewed, and other such information.
                            </Typography>
                            <Typography className="p">
                                <b>Clear Gifs/Web Beacons Information:</b>
                                When you use the Service, we may employ clear gifs (also known as web beacons) which are used to anonymously track the online usage patterns of our users anonymously. In addition, we may also use clear gifs
                                in HTML-based emails sent to our users to track which emails are opened and which links are clicked by recipients. The information is allows for more accurate reporting and improvement of the Service. We use
                                or may use cookies, log file, and clear gifs information to: (a) remember information so that you will not have to re-enter it during your visit or the next time you visit the site; (b) provide custom,
                                personalized content and information; (c) monitor the effectiveness of our Service; (d) monitor aggregate metrics such as total number of visitors, traffic, and demographic patterns; (e) diagnose or fix
                                technology problems reported by our Users or engineers that are associated with certain IP addresses; (f) help you efficiently access your information after you sign in; and (h) track User Content and Users
                                to the extent necessary to comply as a service provider with the Digital Millennium Copyright Act; (i) serve Online Advertising (please see below) to your browser or device. To learn more about cookies, clear
                                gifs/web beacons and related technologies, you may wish to visit the Network Advertising Initiative’s online resources, at
                                <a href="http://www.networkadvertising.org" target="_blank" class="querylink" rel="nofollow">
                                    www.networkadvertising.org.
                                </a>
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={10} sm={3}>
                            <Typography variant={"h2"} display={"inline"} className="privacy-title-sub">
                                2.SHARING OF YOUR INFORMATION
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={7}>
                            <Typography className="p">
                                Personally Identifiable Information: We will not rent or sell your Personally Identifiable Information to third parties outside the CodingHero and its controlled subsidiaries and affiliates without your
                                consent, except as noted below:
                            </Typography>
                            <li>
                                We may share your Personally Identifiable Information with third-party business partners for the purpose of providing the Service to you. Those business partners will be given limited access to the Personally
                                Identifiable Information that is reasonably necessary to deliver the Service, and we will require that such third parties comply with this Privacy Policy.
                            </li>
                            <li>
                                Any Personal Identifiable Information or content that you voluntarily disclose for posting to the Service, such as User Content, becomes available to the public, as controlled by any applicable privacy
                                settings. To change your privacy settings on the Service, you may contact hello@codinghero.ai or accessing your user profile page for some settings. If you remove information that you posted to the Service,
                                copies may remain viewable in cached and archived pages of the Service, or if other Users have copied or saved that information.
                            </li>
                            <li>
                                We may at any time buy or sell/divest the company, or any combination of its products, services, assets and/or businesses. Personally Identifiable Information such as customer names and email addresses, and
                                other user information related to the Service will likely be one of the items transferred in these types of transactions. We may also transfer or assign such information in the course of corporate
                                divestitures, mergers, acquisitions, bankruptcies, dissolutions or similar transactions or proceedings.
                            </li>
                            <li>
                                CodingHero will disclose Personally Identifiable Information where required to do so by law or subpoena or if we reasonably believe that such action is necessary to (a) conform to the law, comply with legal
                                process served on us or our affiliates, or investigate, prevent, or take action regarding suspected or actual illegal activities; (b) to exercise or protect the rights, property, or personal safety of
                                CodingHero, our Users or others.
                            </li>
                            <Typography className="p">
                                <b>Non-Personally Identifiable Information: </b>
                                We may share Non-Personally Identifiable Information, including information obtained through tools such as cookies, log files, and clear gifs (such as anonymous usage data, referring/exit pages and URLs,
                                platform types, number of clicks, etc.) with our third-party business partners for the purposes described in the section above on “How We Collect and Use Information.” We may also aggregate or otherwise strip
                                data of all personally identifying characteristics and may share that aggregated, anonymized data with our third party business partners.
                            </Typography>
                            <Typography className="p">
                                <b>Online Advertising:</b>
                                We may also share Non-Personally Identifiable Information with our business partners to deliver online advertisements (“ads”) that may be of interest to you. CodingHero may allow third-party ad servers or ad
                                networks to serve advertisements on the Service. These third-party ad servers or ad networks use technology to send, directly to your browser or mobile device, the ads and ad links that appear on the Service.
                                These ad-servers and ad-networks automatically receive your IP address when this happens. They may also use other technologies (such as cookies, JavaScript, and clear gifs, see above) to compile information
                                about your browser’s or device’s visits and usage patterns on the Service (without using any Personally Identifiable Information), and to measure the effectiveness of their ads and to personalize the
                                advertising content. CodingHero does not sell, rent, or share your Personally Identifiable Information with these third-party ad servers or ad networks for such parties’ own marketing purposes, unless you
                                choose in advance to have your information shared for this purpose. However, please note that if an advertiser asks CodingHero to show an ad to a certain audience and you respond to such an ad, the advertiser
                                or ad server may conclude that you fit the description of the audience they are trying to reach. In addition, third-party advertisers may use Non-Personally Identifiable information (e.g., information that
                                does not include your name, address, email address, or telephone number) about your visits to this and other websites in order to provide advertisements on this and other websites about third-party goods and
                                services that may be of interest to you. If you would like more information about this practice and to know your choices about not having this information used by these companies, click here [link to policy
                                and opt-out for relevant advertiser, e.g.
                                <a href="http://www.google.com/privacy_ads.html for Google or http://networkadvertising.org/managing/opt_out.asp" class="querylink" target="_blank">
                                    http://www.google.com/privacy_ads.html for Google or http://networkadvertising.org/managing/opt_out.asp{" "}
                                </a>
                                for a number of advertisers including Google.
                            </Typography>
                            <Typography className="p">
                                The CodingHero Privacy Policy not apply to, and we cannot control the activities of, third-party advertisers. Please consult the respective privacy policies of such advertisers or contact such advertisers for
                                more information.
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={10} sm={3}>
                            <Typography variant={"h2"} display={"inline"} className="privacy-title-sub">
                                4.YOUR CHOICES ABOUT YOUR INFORMATION
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={7}>
                            <Typography className="p">
                                Opting Out of Collection of Personally Identifiable Information: You may, of course, decline to submit any Personally Identifiable Information through the Service, in which case the CodingHero may not be able
                                to provide certain services to you.
                            </Typography>
                            <Typography className="p">
                                Modifying Your Account Information and Settings: You may update your account information and email-communication preferences at any time by e-mailing hello@codinghero.ai You can also stop receiving
                                promotional email communications from us by clicking on the “unsubscribe link” provided in such communications. We make every effort to promptly process all unsubscribe requests. As noted above, you may not
                                opt out of Service-related communications (e.g., account verification, purchase and billing confirmations and reminders, changes/updates to features of the Service, technical and security notices). If you
                                have any questions about reviewing or modifying your account information, you can contact us directly at hello@codinghero.ai
                            </Typography>
                            <Typography className="p">
                                <b>Opting Out of Collection of Non-Personally Identifiable Information:</b> The only way to completely “opt out” of the collection of any information through cookies or other technology is to actively manage
                                the settings on your browser. Please refer to your browser’s technical information for instructions on how to delete and disable cookies, and other tracking/recording tools. (To learn more about cookies,
                                clear gifs/web beacons and related technologies, you may wish to visit the Network Advertising Initiative’s online resources, athttp://www.networkadvertising.org.) Note that disabling cookies on your browser
                                prevents CodingHero or its business partners from tracking your browser’s activities in relation to the Service, and for use in targeted online advertising. However, doing so may disable many of the features
                                available through the Service.
                            </Typography>
                            <Typography className="p">
                                Opting Out of Online AdvertisingAs noted above, we may share Non-Personally-Identifiable Information with our trusted third-party business partners to deliver online advertisements to your browser or mobile
                                device through the Service, and/or to third-party websites and services. If you would like more details about this practice and your choices about how to opt out of the collection and use of your information
                                by these companies for online advertising purposes, click here:{" "}
                                <a href=" http://www.google.com/privacy_ads.html" target="_blank" class="querylink" rel="nofollow">
                                    http://www.google.com/privacy_ads.html for Google.
                                </a>
                            </Typography>
                            <Typography className="p">
                                <b>User-Generated Content:</b> Following termination or deactivation of your Member account, CodingHero may retain your profile information and User Content for a commercially reasonable time for backup,
                                archival, or audit purposes. Furthermore, CodingHero may retain and continue to use indefinitely all information (including User Content) contained in your communications to other Users or posted to public or
                                semi-public areas of the Service after termination or deactivation of your Member account.
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={10} sm={3}>
                            <Typography variant={"h2"} display={"inline"} className="privacy-title-sub">
                                5.LINKS TO OTHER WEB SITES AND SERVICES
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={7}>
                            <Typography className="p">
                                We are not responsible for the practices employed by websites or services linked to or from the Service, nor the information or content contained therein. Please remember that when you use a link to go from
                                the Service to another website, our Privacy Policy does not apply to third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on
                                our website, are subject to that third party’s own rules and policies. Please read over those rules and policies before proceeding.
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={10} sm={3}>
                            <Typography variant={"h2"} display={"inline"} className="privacy-title-sub">
                                6.CHANGES TO OUR PRIVACY POLICY
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={7}>
                            <Typography className="p">
                                CodingHero may, in its sole discretion, modify or update this Privacy Policy from time to time, and so you should review this page periodically. When we change the policy, we will update the ‘last modified’
                                date at the top of this page. If there are material changes to this Privacy Policy or in how CodingHero will use your personally identifiable information, we will notify you either by prominently posting a
                                notice of such changes prior to implementing the change or by directly sending you a notification.
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>

                    <Grid container>
                        <Grid item xs={1} sm={1}></Grid>
                        <Grid item xs={10} sm={3}>
                            <Typography variant={"h2"} display={"inline"} className="privacy-title-sub">
                                7.HOW TO CONTACT US
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={7}>
                            <Typography className="p">If you have any questions about this Privacy Policy or the Service, please contact us at hello@codinghero.ai</Typography>
                        </Grid>
                        <Grid item xs={1} sm={1}></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default PrivacyPage;
