import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Typography, Hidden, Button } from '@material-ui/core';

import { Phone as PhoneIcon } from '@material-ui/icons';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import { handleCallTo } from '../../../../constants/constants';

import './MobileFooter.scss';
import { EnquiryFormMob } from '../CoursePageSections';

import { useCookies } from 'react-cookie';

const MobileFooter = ({
    stickyForm,
    hiddenFieldsForFormData,
    currency,
    coursePrice,
    courseResponse,
    setAlertOpen,
    coursePlanId
}) => {
    const [open, setOpen] = useState(false);
    const currentURL = useLocation().pathname;
    const [cookies, setCookie] = useCookies(['isBrochureEnquiry']);
    const toggleDialog = () => {
        setOpen(!open);
        setCookie('isBrochureEnquiry', false, { path: currentURL });
    };
    return (
        <Hidden mdUp>
            <Grid container spacing={0} className='grid-row mobile-footer'>
                <Grid
                    item
                    className='has-border'
                    onClick={() => handleCallTo('(+91) 636 305 0154')}
                >
                    <Button className='full-width-button'>
                        <PhoneIcon fontSize='small'></PhoneIcon>
                        <Typography className='footer-button-text'>
                            Call Now
                        </Typography>
                    </Button>
                </Grid>
                <Grid
                    item
                    className='has-border'
                    onClick={() => toggleDialog()}
                >
                    <Button className='full-width-button'>
                        <Typography className='footer-button-text'>
                            Enquire Now
                        </Typography>
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        className='full-width-button'
                        href='whatsapp://send?phone=919900100342&text=example'
                    >
                        <WhatsAppIcon fontSize='small'></WhatsAppIcon>

                        <Typography className='footer-button-text'>
                            Chat Now
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <EnquiryFormMob
                stickyForm={stickyForm}
                coursePlanId={coursePlanId}
                hiddenFieldsForFormData={hiddenFieldsForFormData}
                coursePrice={coursePrice}
                currency={currency}
                open={open}
                toggleDialog={toggleDialog}
                courseResponse={courseResponse}
                setAlertOpen={setAlertOpen}
            />
        </Hidden>
    );
};

export default MobileFooter;
