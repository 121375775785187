import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Grid, Typography, Box } from '@material-ui/core';

import './Breadcrumbs.scss';

const Breadcrumbs = () => {
    const [urlArray, setUrlArray] = useState(
        window.location.pathname.split('/')
    );
    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            setUrlArray(window.location.pathname.split('/'));
        });
    }, [history]);

    const breadcrumbPath = [
        <Typography display={'inline'} key={`breadcrumb-key-n`}>
            <Link className='breadcrumb-links' to='/'>
                Home
            </Link>
        </Typography>
    ];

    let newPath = '';
    const breadcrumbLength = urlArray.length;
    breadcrumbPath.push(
        urlArray.map((path, i) => {
            if (path) {
                newPath += `/${path}`;
                let pathText = path.replace(/-/g, ' ');
                if (i != breadcrumbLength - 1)
                    return (
                        <Typography
                            display={'inline'}
                            key={`breadcrumb-key-${i}`}
                        >
                            <Link
                                className='breadcrumb-links'
                                to={newPath}
                            >{` > ${pathText}`}</Link>
                        </Typography>
                    );
                else
                    return (
                        <Typography
                            display={'inline'}
                            key={`breadcrumb-key-${i}`}
                        >
                            <Link
                                className='breadcrumb-links active'
                                to='#'
                            >{` > ${pathText}`}</Link>
                        </Typography>
                    );
            }
        })
    );

    return (
        <Box className="breadcrumb-container">
            <Grid className='breadcrumb max-width'>{breadcrumbPath}</Grid>
        </Box>
    );
};

export default Breadcrumbs;
