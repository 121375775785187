import React from 'react';

import { Grid, Typography, Box } from '@material-ui/core';

import {
    Star as StarFilledIcon,
    StarBorder as StarEmptyIcon,
    StarHalf as StarHalfIcon
} from '@material-ui/icons';

import { isObjEmpty } from '../../../../constants/constants';

import './HeroSection.scss';

const HeroSection = ({ ratingsReviewsData, title, subtitle }) => {
    const calucalteStars = () => {
        const rating = parseFloat(ratingsReviewsData.ratings),
            stars = [];
        let setEmpty = false;

        for (let i = 1; i <= 5; i++) {
            if (!setEmpty) {
                if (rating > i) {
                    stars.push(
                        <StarFilledIcon
                            fontSize={'small'}
                            key={`star-${i}`}
                            className='star-icon'
                        />
                    );
                } else if (rating == i) {
                    stars.push(
                        <StarFilledIcon
                            fontSize={'small'}
                            key={`star-${i}`}
                            className='star-icon'
                        />
                    );
                    setEmpty = true;
                } else {
                    stars.push(
                        <StarHalfIcon
                            fontSize={'small'}
                            key={`star-${i}`}
                            className='star-icon'
                        />
                    );
                    setEmpty = true;
                }
            } else {
                stars.push(
                    <StarEmptyIcon
                        fontSize={'small'}
                        key={`star-${i}`}
                        className='star-icon'
                    />
                );
            }
        }

        return stars;
    };

    const starIcons = calucalteStars();
    return (
        <Box className='full-width hero'>
            <Grid container className='max-width'>
                <Grid item xs={12} md={11}>
                    <Box display="flex" flexDirection="row">
                        <h1 className='hero-title-text'>{title}</h1>
                        <Box className="live-online">Live Online Classes</Box>
                    </Box>
                    {!isObjEmpty(ratingsReviewsData) &&
                    ratingsReviewsData.ratings ? (
                        <Grid container className='hero-ratings-container'>
                            <Box display="flex" flexDirection="row" className="rating-container">
                                <Box className='ratings-icon-container'>{starIcons}</Box>
                                
                                <Typography
                                    display={'inline'}
                                    className='ratings-number'
                                >
                                    {ratingsReviewsData.ratings}
                                </Typography>
                                <Typography
                                    display={'inline'}
                                    className='reviewers-number'
                                >
                                    {`(${ratingsReviewsData.reviews} Happy Customers)` }
                                </Typography>
                            </Box>
                            
                        </Grid>
                    ) : null}
                    <Grid container>
                        <Grid item md={8}
                            xs={12}
                            sm={12}>
                            <Box pr={2} pt={2}>
                                <Typography
                                    variant={'h2'}
                                    className='hero-text subtitle medium'
                                >
                                    {subtitle }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={11}>
                    <Box mt={5} mb={3}>
                        <Grid container>
                            <Grid item md={3}>
                                <Grid container spacing={4} className='key-points-container'>
                                    <Grid item xs={2} sm={2}>
                                        <Box pt={2}><img src="/images/course-page/maths.png" alt="CodingHero" className="key-points-icon"/></Box>
                                    </Grid>
                                    <Grid item xs={10} sm={10}>
                                        <Box className="key-points-title">Coding Strengthens Maths Performance </Box>
                                        <Box className="key-points-text">
                                        Multiple research has proven that learning to code reinforces math skills, and helps kids visualize abstract concepts in real-world situations leading to improved performance in Maths. 
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3}>
                                <Grid container spacing={4} className='key-points-container'>
                                    <Grid item xs={2} sm={2}>
                                        <Box pt={2}><img src="/images/course-page/creativity.png" alt="CodingHero" className="key-points-icon"/></Box>
                                    </Grid>
                                    <Grid item xs={10} sm={10}>
                                        <Box className="key-points-title">Coding Improves Creativity</Box>
                                        <Box className="key-points-text">
                                        When kids learn to code, they learn through experimentation, which in turn strengthens their brains, allowing them to find creative solutions to problems.
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={3}>
                                <Grid container spacing={4} className='key-points-container'>
                                    <Grid item xs={2} sm={2}>
                                        <Box pt={2}><img src="/images/course-page/improve.png" alt="Kids Who Code, Perform Better in Academics" className="key-points-icon"/></Box>
                                    </Grid>
                                    <Grid item xs={10} sm={10}>
                                        <Box className="key-points-title">Better Academics Performance</Box>
                                        <Box className="key-points-text">
                                        Not only mathematics, but coding also improves language skills and problem-solving skills leading to an overall better academeic performance. 
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default HeroSection;
