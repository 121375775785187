// import installed dependencies
import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';

// import corresponding style
import './Footer.scss';
import { CountryContext } from '../../contexts/country/CountryContext';

// import constants
import { handleMailTo } from '../../constants/constants';

import countries from '../../config/Country';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Footer = () => {
    const [countryState, setCountryState] = useContext(CountryContext);
    if (!countryState) {
        return null;
    }
    const handleFilterClick = (newValue) => {
        if (newValue) {
            if (newValue.code !== 'IN' && newValue.code !== 'US') {
                const newCountry = {
                    code: 'ROW',
                    name: newValue.name
                };
                setCountryState(newCountry);
            } else {
                setCountryState(newValue);
            }
        }
    };

    const handleSocialIconClick = (e) => {
        window.open(e.target.getAttribute('data-href'), '_blank');
        //window.location = e.target.getAttribute('data-href');
    };

    return (
        <Grid container className='full-width' justify={'center'} id='footer'>
            <Grid item md={12} sm={12} className='footer'>
                <Box className='max-width'>
                    <Grid item xs={12}>
                        <img
                            alt='coding hero logo'
                            src='/images/coding-hero/logo.png'
                        />
                    </Grid>
                    <Grid container justify={'center'}>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={4}
                            className='social-media-container'
                        >
                            <Typography className='footer-item'>
                                Find us on
                            </Typography>
                            <Grid container>
                                <Grid
                                    item
                                    xs={2}
                                    sm={3}
                                    md={2}
                                    className='social'
                                    onClick={handleSocialIconClick}
                                    data-href='https://twitter.com/codingheroai'
                                    style={{
                                        backgroundImage:
                                            'URL(/images/coding-hero/twitter.png)'
                                    }}
                                ></Grid>
                                <Grid
                                    item
                                    xs={2}
                                    sm={3}
                                    md={2}
                                    className='social'
                                    onClick={handleSocialIconClick}
                                    data-href='https://www.facebook.com/CodingHero-112561877084771'
                                    style={{
                                        backgroundImage:
                                            'URL(/images/coding-hero/facebook.png)'
                                    }}
                                ></Grid>
                                <Grid
                                    item
                                    xs={2}
                                    sm={3}
                                    md={2}
                                    className='social'
                                    onClick={handleSocialIconClick}
                                    data-href=''
                                    style={{
                                        backgroundImage:
                                            'URL(/images/coding-hero/instagram.png)'
                                    }}
                                ></Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Typography
                                variant={'h3'}
                                className='footer-items-title'
                            >
                                About Us
                            </Typography>
                            <Typography className='footer-item'>
                                About CodingHero
                            </Typography>
                            {/* <Typography className='footer-item'>Press</Typography>
                            <Typography className='footer-item'>Careers</Typography> */}
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Typography
                                variant={'h3'}
                                className='footer-items-title'
                            >
                                Contact Us
                            </Typography>
                            <Typography className='footer-item'>
                                +91 636 305 0154
                            </Typography>
                            <Typography className='footer-item'>
                                +1 510 400 8198
                            </Typography>

                            {/* <Typography className='footer-item'>Videos</Typography>
                            <Typography className='footer-item'>
                                Affiliates
                            </Typography> */}
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Typography
                                variant={'h3'}
                                className='footer-items-title'
                            >
                                Educators
                            </Typography>
                            <Link
                                to='privacy-policy'
                                className='footer-item footer-link'
                            >
                                Privacy Policy
                            </Link>
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <Autocomplete
                                className='custom-select'
                                options={countries}
                                value={countryState}
                                onChange={(event, newValue) => {
                                    handleFilterClick(newValue);
                                }}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                renderOption={(option) => (
                                    <React.Fragment>
                                        {option.name} ({option.code})
                                    </React.Fragment>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label='Choose a country'
                                        className='country-select-border'
                                        //variant='outlined'
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password' // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <hr className='divider' />
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography className='footer-item footer-end-item pull-left'>
                                ©2020 - codingHero. All rights reserved
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Link
                                to='#'
                                className='footer-item footer-link footer-end-item pull-right'
                                onClick={handleMailTo}
                            >
                                hello@codinghero.ai
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            <Grid item md={12} sm={12}>
                <Box
                    className='max-width'
                    display='flex'
                    flexDirection='column'
                    mt={3}
                    mb={8}
                >
                    <Typography variant='body2'>
                        Do you want your kid to showcase her / his creating
                        abilities by using the latest emerging technologies? We
                        at Coding Hero provide a favorable environment and
                        opportunities to explore various platforms such as game
                        development, mobile app development. Our online coding
                        courses are designed to suit kids' learning pace. We not
                        only teach kids the basics of coding like logic,
                        algorithms but also make them proficient in logical
                        thinking that enable kids to create wonderful games,
                        animations, and apps.
                    </Typography>

                    <Box mt={3}>
                        <Typography variant='h6'>About CodingHero</Typography>
                    </Box>

                    <Typography variant='body2'>
                        At CodingHero, the kids start learning code through our
                        online coding classes. Our online courses introduce the
                        kids from 5 years of age to the whole new exciting world
                        of coding by learning web development, game development,
                        and mobile app development and that too from the comfort
                        of your home. CodingHero’s specially designed curriculum
                        is organized around fun-driven learning, which in turn
                        develops interest among kids and they adopt it as a part
                        of their learning. Kids begin to code using block-based
                        visual language, which helps them recognize patterns and
                        master programming concepts like sequencing, loops,
                        conditional logic, and algorithmic thinking.
                    </Typography>

                    <Box mt={3}>
                        <Typography variant='h6'>
                            Is it necessary to start coding that early?
                        </Typography>
                    </Box>

                    <Typography variant='body2'>
                        This is the most commonly asked question by parents.
                        When it comes to preparing your child for the future,
                        helping them to learn coding is one of the best options.
                        The online coding classes for kids at CodingHero help
                        your child develop skills, not only in math and science
                        but also in critical life skills like problem-solving,
                        critical thinking, communication, organization, and
                        planning. When a kid learns game development, mobile app
                        development, or Python code through our specially
                        designed online coding courses the kid develops an
                        algorithmic approach in problem-solving.
                    </Typography>

                    <Box mt={3}>
                        <Typography variant='h6'>
                            What will my kid learn in online coding classes?
                        </Typography>
                    </Box>

                    <Typography variant='body2'>
                        As a parent when you think about important life skills
                        that your kid should learn apart from the academic
                        curriculum, coding is the most important among others.
                        The online training program in coding imbibes in your
                        kid creativity and problem-solving skills apart from
                        improving kid’s academic performance. The online coding
                        classes offered by CodingHero helping the kids learn
                        <ul>
                            <li>Game Development</li>
                            <li>Animations</li>
                            <li>Mobile App Development</li>
                            <li>Web Development</li>
                            <li>Python</li>
                            <li>Artificial Intelligence</li>
                            <li>Machine Learning</li>
                            <li>Data Science</li>
                        </ul>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Footer;
