import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
    Tabs,
    Tab,
    Grid,
    Typography,
    Divider,
    Button,
    Box
} from '@material-ui/core';

import { EnquiryFormMob } from '../CoursePageSections';
import { detectMob } from '../../../../constants/constants';

import './UpcomingBatches.scss';

const UpcomingBatches = ({
    setCoursePlanId,
    coursePlanId,
    batchData,
    stickyForm,
    hiddenFieldsForFormData,
    currency,
    coursePrice,
    courseResponse,
    setAlertOpen,
    setCookie,
    selectedBatch,
    setSelectedBatch
}) => {
    const [activeBatchTab, setActiveBatchTab] = useState(0);
    const [open, setOpen] = useState(false);
    const toggleDialog = () => {
        setOpen(!open);
    };

    const currentURL = useLocation().pathname;
    const history = useHistory();

    const setActiveBatchTabStatus = (e) => {
        const tab = e.target.closest('button');
        setActiveBatchTab(parseInt(tab.getAttribute('data-value')));
        const allBatchContainers = document.getElementsByClassName(
            'batch-container'
        );
        const allTabs = document.getElementsByClassName('batch-tab');
        for (let i = 0; i < allBatchContainers.length; i++) {
            allBatchContainers[i].classList.remove('active-batch');
            if (
                allBatchContainers[i].classList.contains(
                    tab.getAttribute('data-classname')
                )
            ) {
                document
                    .getElementsByClassName(
                        `batch-container ${tab.getAttribute('data-classname')}`
                    )[0]
                    .classList.add('active-batch');
            }
        }
    };

    const batchHeads = [];
    const batchDetails = {
        online: [],
        offline: []
    };

    const getBatchDay = (batchDataItem) => {
        const batchDay = [];
        if (batchDataItem.monday) {
            batchDay.push(
                <Grid item xs={4} md={2} key={`batch-day-monday`}>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            {batchDataItem.monday}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            Monday
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (batchDataItem.tuesday) {
            batchDay.push(
                <Grid item xs={4} md={2} key={`batch-day-tuesday`}>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            {batchDataItem.tuesday}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            Tuesday
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (batchDataItem.wednesday) {
            batchDay.push(
                <Grid item xs={4} md={2} key={`batch-day-wednesday`}>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            {batchDataItem.wednesday}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            Wednesday
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (batchDataItem.thursday) {
            batchDay.push(
                <Grid item xs={4} md={2} key={`batch-day-thursday`}>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            {batchDataItem.thursday}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            Thursday
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (batchDataItem.friday) {
            batchDay.push(
                <Grid item xs={4} md={2} key={`batch-day-friday`}>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            {batchDataItem.friday}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            Friday
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (batchDataItem.saturday) {
            batchDay.push(
                <Grid item xs={4} md={2} key={`batch-day-saturday`}>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            {batchDataItem.saturday}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            Saturday
                        </Typography>
                    </Grid>
                </Grid>
            );
        }
        if (batchDataItem.sunday) {
            batchDay.push(
                <Grid item xs={4} md={2} key={`batch-day-sunday`}>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            {batchDataItem.sunday}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography className='regular-text medium'>
                            Sunday
                        </Typography>
                    </Grid>
                </Grid>
            );
        }

        return batchDay;
    };

    const handleButtonClick = (e) => {
        if (!selectedBatch) {
            alert('Please select a batch!');
            return;
        }

        detectMob()
            ? toggleDialog()
            : document
                  .getElementsByClassName('MuiOutlinedInput-input')[0]
                  .focus();

        setCookie('isBrochureEnquiry', true, { path: currentURL });
        const userDetails = {
            name: '',
            email: '',
            geolocation: '',
            code: '',
            phone: ''
        };
        history.push({
            pathname: `${history.location.pathname}/enroll`,
            state: {
                detail: courseResponse,
                userDetails: userDetails,
                selectedBatch: selectedBatch,
                coursePlanId: coursePlanId
            }
        });
    };

    const handleRadioChange = (e) => {
        setSelectedBatch(e.target.getAttribute('data-batch'));
        setCoursePlanId(e.target.getAttribute('data-courseplanid'));
    };

    let trainingModes = {
        online: false,
        offline: false
    };

    for (let i = 0; i < batchData.length; i++) {
        const batchDay = getBatchDay(batchData[i]);

        if (trainingModes[batchData[i].mode_of_training] === false) {
            batchHeads.push(
                <Tab
                    label={batchData[i].mode_of_training.toUpperCase()}
                    data-value={i}
                    data-classname={batchData[i].mode_of_training}
                    onClick={setActiveBatchTabStatus}
                    className='batch-tab'
                    key={`batch-head-${i}`}
                ></Tab>
            );
            trainingModes[batchData[i].mode_of_training] = true;
        }
        if (batchData[i].mode_of_training === 'online') {
            batchDetails.online.push(
                <Grid
                    container
                    className='padded-grid-upcoming-batches online-certified'
                    key={`online-batch-${i}`}
                >
                    <Grid item xs={2} sm={1}>
                        <input
                            onChange={handleRadioChange}
                            type='radio'
                            data-batch={batchData[i].batch_id}
                            data-courseplanid={batchData[i].course_plan_id}
                            name={batchData[i].mode_of_training}
                            color={'primary'}
                            className='batch-radio'
                        />
                    </Grid>
                    <Grid item xs={10} sm={11}>
                        <Typography
                            variant={'h3'}
                            className='section-subtitle large'
                        >
                            {`${batchData[i].batch_start_date} to ${batchData[i].batch_end_date}`}
                        </Typography>
                        <Grid container>
                            <Typography className='regular-text bold large'>
                                TIMING
                            </Typography>
                        </Grid>
                        <Grid container>{batchDay}</Grid>
                    </Grid>
                </Grid>
            );
        } else if (batchData[i].mode_of_training === 'offline') {
            batchDetails.offline.push(
                <Grid
                    container
                    className='padded-grid-upcoming-batches online-certified'
                    key={`offline-batch-${i}`}
                >
                    <Grid item xs={2} sm={1}>
                        <input
                            onChange={handleRadioChange}
                            type='radio'
                            data-batch={batchData[i].batch_id}
                            name={batchData[i].mode_of_training}
                            color={'primary'}
                            className='batch-radio'
                        />
                    </Grid>
                    <Grid item xs={10} sm={11}>
                        <Typography
                            variant={'h3'}
                            className='section-subtitle large'
                        >
                            {`${batchData[i].batch_start_date} to ${batchData[i].batch_end_date}`}
                        </Typography>
                        <Grid container>
                            <Typography className='regular-text bold large'>
                                TIMING
                            </Typography>
                        </Grid>
                        <Grid container>{batchDay}</Grid>
                    </Grid>
                </Grid>
            );
        }
    }

    if (!batchData.length) {
        return null;
    }

    return (
        <Box className='max-width'>
            <Grid container className='upcoming-batches'>
                <Grid item sm={9} md={9} xs={12}>
                    <Grid container className='course-grid-row'>
                        <h2 className='subtitle full-width text-left'>
                            Upcoming Batches
                        </h2>
                    </Grid>
                    <Grid container className='course-grid-content'>
                        <Grid container>
                            <Tabs value={activeBatchTab}>{batchHeads}</Tabs>
                            <Grid
                                container
                                className='batch-container batches active-batch'
                                data-mode='online'
                            >
                                {batchDetails.online}
                            </Grid>
                            <Grid
                                container
                                className='batch-container batches active-batch'
                                data-mode='offline'
                            >
                                {batchDetails.offline}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item sm={2}></Grid>
                            <Grid item sm={8}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item sm={2}></Grid>
                        </Grid>

                        <Grid
                            container
                            className='padded-grid-upcoming-batches active-batch'
                        >
                            <Grid item sm={7} lg={9}></Grid>
                            <Grid item sm={5} lg={3}>
                                <Button
                                    onClick={handleButtonClick}
                                    variant='outlined'
                                    color='primary'
                                    className='enroll-now-btn'
                                >
                                    ENROLL NOW
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <EnquiryFormMob
                        stickyForm={stickyForm}
                        hiddenFieldsForFormData={hiddenFieldsForFormData}
                        coursePrice={coursePrice}
                        currency={currency}
                        open={open}
                        toggleDialog={toggleDialog}
                        courseResponse={courseResponse}
                        setAlertOpen={setAlertOpen}
                    />
                </Grid>
            </Grid>
            <EnquiryFormMob
                stickyForm={stickyForm}
                hiddenFieldsForFormData={hiddenFieldsForFormData}
                coursePrice={coursePrice}
                currency={currency}
                open={open}
                toggleDialog={toggleDialog}
                courseResponse={courseResponse}
                setAlertOpen={setAlertOpen}
                selectedBatch={selectedBatch}
                setSelectedBatch={setSelectedBatch}
            />
        </Box>
    );
};

export default UpcomingBatches;
