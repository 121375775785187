const OrderDetails = (amount, name, email, phone, geolocation, course) => {
    let order = {
        user_id: '',
        user_type: '',
        metadata: {
            email: email,
            name: name,
            phone_number: phone,
            address: geolocation
        },
        total_amount: amount.totalAmount,
        payable_amount: amount.payableAmount,
        tax: amount.taxAmount,
        mode_of_payment: 'online',
        currency: course.pricing.currency,
        location: geolocation,
        coupon_code: '',
        flow: course.productInfo.flow,
        no_of_learners: 1,
        status: 'payment_pending',
        created_by: 'website'
    };
    return order;
};
const OrderItemDetails = (course, amount) => {
    let orderItems = {
        product_id: course._id['$oid'],
        order_id: '',
        no_of_learners: 1,
        coupon_code: '',
        discount: 0,
        mrp: course.pricing.mrp,
        selling_price: amount.payableAmount,
        tax: amount.taxAmount,
        source_sbu: course.productInfo.BU,
        destination_sbu: course.productInfo.BU,
        payment_type: amount.selectedPlan.planType
    };

    let paymentplans = [];
    let planDetails = amount.selectedPlan;

    if (planDetails) {
        for (let i = 0; i < planDetails.collectionCycle.length; i++) {
            // let currentPlan = planDetails.collectionCycle[i];
            let plan = {
                total_amount: amount.payableAmount,
                due_date: new Date()
                    .toLocaleString()
                    .replace(/[\/\\]/g, '-')
                    .replace(',', ''),
                instalmentSize: amount.selectedPlan.noOfInstalment,
                days: 0,
                pricing_plan_id: amount.selectedPlan._id,
                tax: amount.taxAmount,
                status: 'payment_pending',
                payable_amount: amount.payableAmount
            };

            paymentplans.push(plan);
        }
    }
    orderItems.payment_plans = paymentplans;
    return orderItems;
};
const ProductDetails = (course, name, phone, email) => {
    let product_details = {
        FirstName: name,
        LastName: '',
        Phone: phone,
        EmailAddress: email,
        mx_City: '',
        mx_State: '',
        mx_Country: '',
        mx_Flow: course.productInfo.flow,
        mx_BU: 'CodingHero',
        mx_SBU: 'CodingHero',
        mx_Product_Category: course.productInfo.productCategory,
        mx_Category: course.productInfo.category,
        mx_Product_Group: course.productInfo.productGroup,
        mx_Product: course.productInfo.shortName
    };
    return product_details;
};
const orderPayload = (course, amount, geolocation, name, email, phone) => {
    let order = {
        order: OrderDetails(amount, name, email, phone, geolocation, course),
        orderItems: [OrderItemDetails(course, amount)],
        product_details: ProductDetails(course, name, phone, email)
    };
    return order;
};
export default orderPayload;
