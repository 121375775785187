import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import './KidsDevelopSmall.scss';

const KidsDevelopSectionSmall = () => {
    return (
        <Grid container className='learning-attributes'>
            <Grid
                container
                className='attributes-title-container course-grid-row'
            >
                <Grid item>
                    <Typography
                        variant={'h2'}
                        className='subtitle full-width text-center'
                    >
                        Invest In Your Child
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className='grid-row-content' justify={'center'}>
                <Grid
                    item
                    md={4}
                    sm={'auto'}
                    xs={12}
                    className='attributes-container'
                >
                    <img
                        src='/images/coding-hero/computational-thinking.png'
                        alt='learning attributes'
                        height='120px'
                        width='120px'
                    />
                    <Typography>Computational Thinking</Typography>
                </Grid>
                <Grid
                    item
                    md={4}
                    sm={'auto'}
                    xs={12}
                    className='attributes-container'
                >
                    <img
                        src='/images/coding-hero/logical-reasoning.png'
                        alt='learning attributes'
                        height='120px'
                        width='120px'
                    />
                    <Typography>Logical Reasoning</Typography>
                </Grid>
                <Grid
                    item
                    md={4}
                    sm={'auto'}
                    xs={12}
                    className='attributes-container'
                >
                    <img
                        src='/images/coding-hero/algorithmic-thinking.png'
                        alt='learning attributes'
                        height='120px'
                        width='120px'
                    />
                    <Typography>Algorithimic Thinking</Typography>
                </Grid>
                <Grid
                    item
                    md={4}
                    sm={'auto'}
                    xs={12}
                    className='attributes-container'
                >
                    <img
                        src='/images/coding-hero/problem-solving-skills.png'
                        alt='learning attributes'
                        height='120px'
                        width='120px'
                    />
                    <Typography>Problem Solving Skills</Typography>
                </Grid>
                <Grid
                    item
                    md={4}
                    sm={'auto'}
                    xs={12}
                    className='attributes-container'
                >
                    <img
                        src='/images/coding-hero/creativity.png'
                        alt='learning attributes'
                        height='120px'
                        width='120px'
                    />
                    <Typography>Creativity</Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default KidsDevelopSectionSmall ;
