import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent
} from '@material-ui/core';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel
} from '@material-ui/core';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import './BatchSection.scss';

const BatchSection = ({
    course,
    batch,
    selectedBatch,
    setSelectedBatch,
    handleAccordianChange,
    setCoursePlanId
}) => {
    const [open, setOpen] = useState(false),
        [batchDialogOpen, setBatchDialogOpen] = useState(false);

    const handleRadioChange = (event) => {
        setSelectedBatch(event.target.value);
        setCoursePlanId(event.target.getAttribute('data-courseplanid'));
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const batchTbl = [];

    if (selectedBatch) {
        for (let i = 0; i < batch.length; i++) {
            if (batch[i].batch_id == selectedBatch) {
                batchTbl.push(
                    {
                        title: 'Mode of Training',
                        data: batch[i].mode_of_training
                    },
                    {
                        title: 'Batch Selected',
                        data: `${batch[i].batch_start_date} to ${batch[i].batch_end_date}`
                    },
                    {
                        title: 'Day',
                        data: batch[i].batch_days
                    },
                    {
                        title: 'Timing',
                        data: batch[i].batch_timing
                    }
                );
            } else {
                batchTbl.push([
                    {
                        title: 'No Batch Selected',
                        data: 'Please select a Batch'
                    }
                ]);
            }
        }
    } else {
        batchTbl.push({
            title: 'No Batch Selected',
            data: 'Please select a Batch'
        });
    }

    const popupBatchData = batch.map((element, i) => {
        return (
            <FormControlLabel
                key={`payment-page-batch-${i}`}
                className='label'
                control={
                    <Radio
                        inputProps={{
                            'data-courseplanid': element.course_plan_id
                        }}
                        color={'primary'}
                    />
                }
                checked={element.batch_id == selectedBatch ? true : false}
                value={element.batch_id}
                label={
                    <Typography variant={'caption'} className='batch-list'>
                        <Typography>
                            {element.batch_start_date} to{' '}
                            {element.batch_end_date}
                        </Typography>
                        <Typography className='regular-text medium'>
                            TIMING
                        </Typography>
                        <Typography className='regular-text'>
                            Timing {element.batch_timing} | {element.batch_days}
                        </Typography>
                    </Typography>
                }
            />
        );
    });

    const handleButtonClick = () => {
        if (!selectedBatch) {
            setBatchDialogOpen(true);
            return;
        }
        if (open) {
            handleClose();
            return;
        }
        handleAccordianChange('panel3');
    };

    return (
        <Grid container>
            <Grid item xs={12} className='d-flex-center grid-row'>
                <Typography variant={'h2'} className='subtitle large'>
                    {course.productInfo.name}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <CreateIcon onClick={handleClickOpen} className='ico-create' />
                <TableContainer>
                    <Table aria-label='table' className='payment-tbl'>
                        <TableBody>
                            {batchTbl.map((row, i) => (
                                <TableRow key={row.title} key={`batchTbl-${i}`}>
                                    <TableCell component='th' scope='row'>
                                        {row.title}
                                    </TableCell>
                                    <TableCell className='text-bold'>
                                        {row.data}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    variant='contained'
                    onClick={handleButtonClick}
                    className='btn btn-contained'
                >
                    Proceed
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='form-dialog-title'
                    className='modal-md'
                >
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                onClick={handleClose}
                                variant={'caption'}
                                display={'block'}
                                className='close'
                            >
                                X
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <DialogTitle className='regular-text xxl'>
                                Select Batch
                            </DialogTitle>
                            <DialogContent>
                                <FormControl
                                    component='fieldset'
                                    className='batch-details-cntr'
                                >
                                    <RadioGroup
                                        aria-label='batchDetails'
                                        name='batchDetails'
                                        onChange={handleRadioChange}
                                    >
                                        {popupBatchData}
                                    </RadioGroup>
                                </FormControl>
                            </DialogContent>
                            <DialogActions className='justify-content-end'>
                                <Button
                                    variant='contained'
                                    className='btn btn-contained'
                                    onClick={handleButtonClick}
                                >
                                    Select Batch
                                </Button>
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Dialog>
            </Grid>
            <Dialog
                open={batchDialogOpen}
                onClose={() => {
                    setBatchDialogOpen(false);
                }}
            >
                <Grid container className='grid-row'>
                    <Grid item xs={12}>
                        <Typography
                            onClick={() => {
                                setBatchDialogOpen(false);
                            }}
                            variant={'caption'}
                            display={'block'}
                            className='close'
                        >
                            X
                        </Typography>
                    </Grid>
                    <Grid container justify={'center'}>
                        <Typography className='regular-text xl bold'>
                            Please Select a Batch!
                        </Typography>
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
};
export default BatchSection;
