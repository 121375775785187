import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import {
    Grid,
    TextField,
    Radio,
    Typography,
    Button,
    FormControl,
    Dialog
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useCookies } from 'react-cookie';
import { Cancel as CancelIcon } from '@material-ui/icons';

import {
    getCurrencySymbol,
    MAIL_URL,
    COUNTRIES_WITH_CODES
} from '../../../../constants/constants';

import './EnquiryFormMob.scss';

const EnquiryFormMob = ({
    stickyForm,
    hiddenFieldsForFormData,
    currency,
    coursePrice,
    open,
    toggleDialog,
    courseResponse,
    setAlertOpen,
    selectedBatch,
    coursePlanId
}) => {
    const [state, setState] = useState({
        name: '',
        email: '',
        geolocation: '',
        code: '',
        phone: ''
    });
    const [countryCode, setCountryCode] = useState();

    const currencySymbol = getCurrencySymbol(currency);
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['isBrochureEnquiry']);

    const handleInputChange = (event, newValue) => {
        let { name, value } = event.target;
        if (name === 'code') {
            value = newValue;
        }
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleEnquiryFormSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const dataObj = {};
        for (var [key, value] of data.entries()) {
            dataObj[key] = value;
        }
        dataObj.brouchureLink = cookies['isBrochureEnquiry'];
        axios.post(MAIL_URL, dataObj).catch((err) => {
            console.log(err);
        });

        setAlertOpen(true);
        window.gtag_report_conversion(window.location.href);
        const currentPath = history.location.pathname;
        const enrolUrl =
            currentPath[currentPath.length - 1] == '/'
                ? currentPath + 'enroll'
                : currentPath + '/enroll';
        history.push({
            pathname: enrolUrl,
            state: {
                detail: courseResponse,
                userDetails: state,
                selectedBatch: selectedBatch,
                coursePlanId: coursePlanId
            }
        });
    };

    return (
        <Dialog open={open} onClose={() => toggleDialog()}>
            <Grid
                container
                className='interested-form grid-row'
                ref={stickyForm}
            >
                <CancelIcon
                    className='cancel-round-icon'
                    onClick={() => toggleDialog()}
                />
                <Grid>
                    <Typography variant={'h2'} className='subtitle full-width'>
                        Interested In This Program
                    </Typography>
                    <form
                        method='POST'
                        className='full-width'
                        onSubmit={handleEnquiryFormSubmit}
                    >
                        <FormControl className='full-width'>
                            <Grid container>
                                <input
                                    value={hiddenFieldsForFormData.course_id}
                                    name='course_id'
                                    type='hidden'
                                />
                                <input
                                    value={
                                        hiddenFieldsForFormData.product_category
                                    }
                                    name='product_category'
                                    type='hidden'
                                />
                                <input
                                    value={
                                        hiddenFieldsForFormData.product_group
                                    }
                                    name='product_group'
                                    type='hidden'
                                />
                                <input
                                    value={hiddenFieldsForFormData.product}
                                    name='product'
                                    type='hidden'
                                />
                                <input
                                    value={
                                        hiddenFieldsForFormData.mode_of_training
                                    }
                                    name='mode_of_training'
                                    type='hidden'
                                />
                                <input
                                    value={
                                        hiddenFieldsForFormData.brochure || ''
                                    }
                                    name='brochure_link'
                                    type='hidden'
                                />
                                <TextField
                                    required
                                    variant='outlined'
                                    className='input-text full-width'
                                    label='Name'
                                    name='name'
                                    onChange={handleInputChange}
                                />
                                <TextField
                                    required
                                    variant='outlined'
                                    className='input-text full-width'
                                    label='Email'
                                    type={'email'}
                                    name='email'
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid container>
                                <Autocomplete
                                    options={COUNTRIES_WITH_CODES}
                                    value={countryCode}
                                    onChange={(event, newValue) => {
                                        event.target.name = 'code';
                                        handleInputChange(
                                            event,
                                            newValue.phone
                                        );
                                        setCountryCode(newValue);
                                    }}
                                    name='code'
                                    autoHighlight
                                    getOptionLabel={(option) => option.label}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option.phone} {option.label} (
                                            {option.code})
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='Choose a country'
                                            className='country-select-border'
                                            //variant='outlined'
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password' // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                                <TextField
                                    required
                                    variant='outlined'
                                    className='input-text full-width'
                                    label='Phone'
                                    name='phone'
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid container>
                                <TextField
                                    required
                                    variant='outlined'
                                    className='input-text full-width'
                                    label='City'
                                    name='geolocation'
                                    onChange={handleInputChange}
                                />
                            </Grid>
                            <Grid container>
                                <Grid item className='radio-text'>
                                    <Radio
                                        required
                                        name='checkbox_authorize'
                                        color={'primary'}
                                    />
                                    <Typography
                                        className='regular-text'
                                        display={'inline'}
                                    >
                                        I agree to CodingHero’s{' '}
                                        <Link to='/privacy-policy'>
                                            privacy policy
                                        </Link>{' '}
                                        and authorize CodingHero
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container className='grid-row'>
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                    display='inline'
                                >
                                    {`${currencySymbol} ${coursePrice}`}
                                </Typography>
                                <Typography className='regular-text'>
                                    (All inclusive)
                                </Typography>
                            </Grid>
                            <Grid container className='grid-row'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    className='enquire-now-btn'
                                    type='submit'
                                >
                                    ENQUIRE NOW
                                </Button>
                            </Grid>
                        </FormControl>
                    </form>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default EnquiryFormMob;
