const courses = [
    {
        _id: '5b766a373cfa54395f6d232d',
        description: 'artificial-intelligence-course-for-kids',
        data1:
            'Learn the tools and technologies behind Artificial Intelligence and Machine Learning',
        data2:
            'You will learn - Python, Statistics, Data Manipulation, ML and AI',
        flow: 'IN',
        name: 'Artificial Intelligence Foundation',
        priority: 6,
        productCategoryName: 'big-data',
        training_mode: 'online'
    },
    {
        _id: '5b7d43413cfa5406835df44c',
        description: 'web-and-mobile-app-development-course-for-kids',
        data1:
            'Build cool mobile apps, websites and games. Turn your imaginations into reality',
        data2:
            'You will learn - JavaScript, HTML, CSS, Bootstrap, ReactJS, React Native',
        flow: 'IN',
        name: 'Web & Mobile App Development',
        priority: 6,
        productCategoryName: 'big-data',
        training_mode: 'online'
    },
    {
        _id: '5b7f9fcf3cfa54109077d813',
        description: 'design-foundation-course-for-kids',
        data1:
            ' Learn fundamentals of design & illustrations. Learn about colors. fonts, shades etc.',
        data2:
            'Learn to create characters, digital drawings, image editing, 3d-effects etc.',
        flow: 'IN',
        name: 'Design Foundation',
        priority: 3,
        productCategoryName: 'big-data',
        training_mode: 'online'
    }
];

export { courses };
