import React from 'react';

import { Grid, Typography, Divider, Button, Hidden } from '@material-ui/core';
import {
    Star as StarFilledIcon,
    StarBorder as StarEmptyIcon,
    StarHalf as StarHalfIcon
} from '@material-ui/icons';

import './CourseCard.scss';

const CourseCard = ({ courseDetail }) => {
    return (
        <Grid item sm={6} md={6}>
            <Grid className='course-card outer-box' container>
                <Grid container className='card-header'>
                    <Grid item xs={9}>
                        <Grid container className='card-title-container'>
                            <Typography variant={'h2'} className='subtitle'>
                                Analytics for Non-Programmers
                            </Typography>
                            <Grid container>
                                <Grid
                                    item
                                    lg={5}
                                    sm={6}
                                    className='ratings-icon-container'
                                >
                                    <StarFilledIcon
                                        className='star-icon'
                                        fontSize={'small'}
                                    ></StarFilledIcon>
                                    <StarFilledIcon
                                        className='star-icon'
                                        fontSize={'small'}
                                    ></StarFilledIcon>
                                    <StarFilledIcon
                                        className='star-icon'
                                        fontSize={'small'}
                                    ></StarFilledIcon>
                                    <StarFilledIcon
                                        className='star-icon'
                                        fontSize={'small'}
                                    ></StarFilledIcon>
                                    <StarHalfIcon
                                        className='star-icon'
                                        fontSize={'small'}
                                    ></StarHalfIcon>
                                </Grid>
                                <Grid
                                    item
                                    lg={5}
                                    sm={6}
                                    className='ratings-container'
                                >
                                    <Typography
                                        display={'inline'}
                                        className='ratings-number'
                                    >
                                        4.8
                                    </Typography>
                                    <Typography
                                        display={'inline'}
                                        className='reviewers-number'
                                    >
                                        (1,362)
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} className='course-icon-container'>
                        <Typography display={'inline'} className='tag'>
                            Online
                        </Typography>
                        <img
                            className='course-icon'
                            alt='R icon'
                            src='https://acadgild.com/photos/home/popular-course/analytics-for-non-programmers-training-certification.png'
                        />
                    </Grid>
                </Grid>
                <Grid item sm={12} className='card-title-container'>
                    <Typography className='course-program-title'>
                        10 weeks program that prepares you to analyze data, draw
                        insights & make data driven decisions without having to
                        learn coding.
                    </Typography>
                </Grid>
                <Grid item className='divider-container-item'>
                    <Divider variant='middle'></Divider>
                </Grid>
                <Grid container className='course-description-container'>
                    <Grid item sm={6} className='pull-left'>
                        <Grid item>
                            <Typography className='course-subtitle'>
                                TOOLS YOU WILL LEARN
                            </Typography>

                            <Typography
                                display={'inline'}
                                className='course-description'
                            >
                                Excel VBA Macros Tableau
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} className='pull-right'>
                        <Grid container>
                            <Grid item sm={5}>
                                <Typography className='course-subtitle'>
                                    Duration
                                </Typography>
                                <Typography className='course-subtitle'>
                                    Effort
                                </Typography>
                            </Grid>
                            <Grid item sm={7}>
                                <Typography className='course-description'>
                                    10 weeks
                                </Typography>
                                <Typography className='course-description'>
                                    8-10 hrs/week
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className='divider-container-item'>
                    <Divider variant='middle'></Divider>
                </Grid>
                <Hidden mdDown>
                    <Grid container className='course-highlights-container'>
                        <Grid container>
                            <Grid item md={3} className='course-highlight'>
                                <Typography
                                    variant={'h3'}
                                    className='section-subtitle'
                                >
                                    200
                                </Typography>
                                <Typography>Coding Assignments</Typography>
                            </Grid>
                            <Grid item md={3} className='course-highlight'>
                                <Typography
                                    variant={'h3'}
                                    className='section-subtitle'
                                >
                                    3
                                </Typography>
                                <Typography>Industry Cases</Typography>
                            </Grid>
                            <Grid item md={3} className='course-highlight'>
                                <Typography
                                    variant={'h3'}
                                    className='section-subtitle'
                                >
                                    72
                                </Typography>
                                <Typography>Live Mentoring</Typography>
                            </Grid>
                            <Grid item md={3} className='course-highlight'>
                                <Typography
                                    variant={'h3'}
                                    className='section-subtitle'
                                >
                                    5
                                </Typography>
                                <Typography>Real Time Projects</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid container className='course-highlights-container'>
                        <Grid container>
                            <Grid item xs={6} className='course-highlight'>
                                <Typography
                                    variant={'h3'}
                                    className='section-subtitle'
                                >
                                    200
                                </Typography>
                                <Typography>Coding Assignments</Typography>
                            </Grid>
                            <Grid item xs={6} className='course-highlight'>
                                <Typography
                                    variant={'h3'}
                                    className='section-subtitle'
                                >
                                    3
                                </Typography>
                                <Typography>Industry Cases</Typography>
                            </Grid>
                        </Grid>
                        <Divider variant={'middle'}></Divider>
                        <Grid container>
                            <Grid item xs={6} className='course-highlight'>
                                <Typography
                                    variant={'h3'}
                                    className='section-subtitle'
                                >
                                    72
                                </Typography>
                                <Typography>Live Mentoring</Typography>
                            </Grid>
                            <Grid item xs={6} className='course-highlight'>
                                <Typography
                                    variant={'h3'}
                                    className='section-subtitle'
                                >
                                    5
                                </Typography>
                                <Typography>Real Time Projects</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Hidden>
                <Grid container className='card-footer'>
                    <Grid item sm={8}>
                        <Typography className='price-icon' display={'inline'}>
                            &#8377;
                        </Typography>
                        &nbsp;
                        <Typography display={'inline'} className='price-number'>
                            29,498
                        </Typography>
                        &nbsp;&nbsp;
                        <Typography
                            className='price-support-text'
                            display={'inline'}
                        >
                            (All inclusive)
                        </Typography>
                        <Typography className='price-support-text'>
                            - Lowest EMI starting at 0%
                        </Typography>
                    </Grid>
                    <Grid item sm={4}>
                        <Button
                            variant='outlined'
                            color='primary'
                            className='view-details-btn'
                        >
                            VIEW DETAILS
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CourseCard;
