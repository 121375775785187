import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Button, Dialog } from '@material-ui/core';
import { CoursePaymentCard } from '../../../../components/card/Card';

import { orderPayload } from '../paymentPageSections';
import axios from 'axios';

import {
    isObjEmpty,
    getCurrencySymbol,
    ORDER_SERVICE_API_URL,
    RAZORPAY_SCRIPT_URL,
    PRIMARY_COLOR,
    DASHBOARD_URL,
    RZP_KEY
} from '../../../../constants/constants';

import './PaymentSection.scss';

const PaymentSection = ({
    course,
    amount,
    setAmount,
    geolocation,
    name,
    email,
    code,
    phone,
    selectedBatch,
    coursePlanId
}) => {
    const paymentPlans = [...course.pricing.paymentPlans];

    const courseName = course.productInfo.name;

    const [activeClass, setActiveClass] = useState(-1),
        [open, setOpen] = useState(false);

    const paymentHandler = async (e) => {
        if (!amount.selectedPlan || isObjEmpty(amount.selectedPlan)) {
            setOpen(true);
            return;
        }
        const requestPayload = orderPayload(
            course,
            amount,
            geolocation,
            name,
            email,
            '+' + code + '-' + phone
        );
        let orderResponse = await axios.post(
            `${ORDER_SERVICE_API_URL}orders`,
            requestPayload
        );
        const options = {
            key: RZP_KEY,
            amount: amount.totalAmount * 100,
            name: 'CodingHero',
            description: 'Course Name',
            image: '/images/coding-hero/favicon.png',
            handler: RazorPayhandler,
            prefill: {
                name: name,
                email: email,
                contact: phone
            },
            notes: {
                address: geolocation
            },
            theme: {
                color: PRIMARY_COLOR
            }
        };
        async function RazorPayhandler(res) {
            let paymentCreateData = {
                mode_of_payment: 'online',
                gateway: 'razorpay',
                currency: 'inr',
                amount: amount.totalAmount,
                transaction_id: res.razorpay_payment_id,
                order_id: orderResponse.data.data.order._id,
                order_item_id: orderResponse.data.data.orderItems[0]._id,
                payment_plan_id: [amount.selectedPlan._id],
                name: name,
                email: email,
                phone: '+' + code + '-' + phone,
                city: geolocation,
                address: geolocation,
                batch_id: selectedBatch,
                course_id: course._id['$oid'],
                course_plan_id: coursePlanId
            };
            try {
                let paymentResponse = await axios.post(
                    `${ORDER_SERVICE_API_URL}payment/create`,
                    paymentCreateData
                );
                if (paymentResponse.data.status === 200) {
                    debugger;
                    window.location =
                        DASHBOARD_URL +
                        'api/user/enrollment/login/' +
                        paymentResponse.data.data.enrollment_details[0]
                            .one_time_login_token;
                }
            } catch (e) {
                console.log(e);
                alert('Unable to create payment');
            }
        }
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };

    const amountObj = {
        price: amount.payableAmount,
        currency: course.pricing.currency
    };

    const paymentCard = paymentPlans.map((plan, i) => {
        return (
            <CoursePaymentCard
                activeClassName={
                    activeClass === `payment-card-${i}` ? 'selected' : ''
                }
                key={`payment-card-${i}`}
                customClickAction={() => {
                    setActiveClass(`payment-card-${i}`);
                }}
                plan={plan}
                amount={amountObj}
                showDescription={true}
                setAmountPlanObj={setAmount}
                amountPlanObj={amount}
            ></CoursePaymentCard>
        );
    });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = RAZORPAY_SCRIPT_URL;
        script.async = true;
        document.body.appendChild(script);
    });

    return (
        <Grid container className='payment-cntr'>
            <Grid item xs={6} className='grid-row'>
                <Typography variant={'h2'} className='subtitle large'>
                    {courseName}
                </Typography>
            </Grid>
            <Grid item xs={3} className='grid-row'>
                <Typography className='regular-text large bold'>
                    {getCurrencySymbol(course.pricing.currency) +
                        amount.payableAmount.toLocaleString()}
                </Typography>
            </Grid>
            <Grid item xs={3} className='grid-row'>
                {/* <Grid className='coupon-cntr'>
                <input type='text' name='' placeholder='Enter Coupon Code' />
                <a>APPLY</a>
            </Grid> */}
            </Grid>
            <Grid container justify={'center'} className='plan-options'>
                <Grid container justify={'center'}>
                    <Typography className='regular-text large bold'>
                        Select Your Payment Option:
                    </Typography>
                </Grid>
                {paymentCard}
                <Grid item>
                    <Button
                        variant='contained'
                        onClick={paymentHandler}
                        className='btn btn-contained'
                    >
                        Proceed
                    </Button>
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
            >
                <Grid container className='grid-row'>
                    <Grid item xs={12}>
                        <Typography
                            onClick={() => {
                                setOpen(false);
                            }}
                            variant={'caption'}
                            display={'block'}
                            className='close'
                        >
                            X
                        </Typography>
                    </Grid>
                    <Grid container justify={'center'}>
                        <Typography className='regular-text xl bold'>
                            Please Select a Payment Plan!
                        </Typography>
                    </Grid>
                </Grid>
            </Dialog>
        </Grid>
    );
};
export default PaymentSection;
