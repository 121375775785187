import React, { useState, createContext, useEffect } from 'react';
import axios from 'axios';
import { getCountryURL } from "../../constants/constants";

const CountryContext = createContext([{}, () => { }]);

const CountryContextProvider = (props) => {
    const [countryState, setCountryState] = useState();
    useEffect(() => {
        axios.get(getCountryURL).then(response => {
            const code = response.data.location.country.code;
            setCountryState({
                code: response.data.location.country.code,
                name: response.data.location.country.name
            });
        });
    }, []);

    return (
        <CountryContext.Provider value={[countryState, setCountryState]}>
            {props.children}
        </CountryContext.Provider>
    );
};

export { CountryContext, CountryContextProvider };
