import React from 'react';

import {
    Grid,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import './AccordianContainer.scss';

import AccordianList from './accordian-list/AccordianList';

const AccordianContainer = ({ accordianData, dot }) => {
    if (!accordianData) {
        return null;
    }

    const accordianItem = accordianData.map((item, i) => {
        return (
            <Accordion
                className='accordian no-margin'
                key={`${item.title}-${i}`}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className='grid-row'
                >
                    <Typography variant={'h3'} className='section-subtitle'>
                        {item.title}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className='accordian-details'>
                    <AccordianList listData={item.listData} dot={dot} />
                </AccordionDetails>
            </Accordion>
        );
    });

    return (
        <Grid container>
            <Grid item md={8} sm={12} xs={12} className='accordian-container'>
                {accordianItem}
            </Grid>
        </Grid>
    );
};

export default AccordianContainer;
