import React from 'react';

import { Grid, Typography, Divider } from '@material-ui/core';
import PillTag from '../../pill-tag/PillTag';

import './ProjectCard.scss';

const ProjectCard = ({ projectData }) => {
    const pills = [
        'Feature Engineering Techniques',
        'Machine Learning',
        'Clustering'
    ];

    return (
        <>
            <Grid item xs={12} md={5} className='project-card outer-box'>
                <Grid container>
                    <Typography
                        variant={'h3'}
                        className='section-subtitle card-title'
                    >
                        Pharmaceuticals
                    </Typography>
                </Grid>
                <Divider className='card-divider' />
                <Grid container className='pill-container'>
                    <PillTag pillNameArray={pills}></PillTag>
                </Grid>
                <Grid container className='card-content-container'>
                    <Typography className='regular-text-medium'>
                        Data from commercial research has always had a say on
                        how manual tasks are conducted in the pharmaceutical
                        industry. With advancements in machine learning and
                        artificial intelligence, data now plays an even more
                        significant role in prescribing the best medicines for
                        very specific medical conditions. Prescribing the right
                        medicine is essentially a classification problem. At
                        Acadgild, we focus on Image Analytics, NLP, Text
                        Analysis and Predictive Analytics using CART
                        (Classification and Regression Tree) models.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className='project-card outer-box'>
                <Grid container>
                    <Typography
                        variant={'h3'}
                        className='section-subtitle card-title'
                    >
                        E-Commerce
                    </Typography>
                </Grid>
                <Divider className='card-divider' />
                <Grid container className='pill-container'>
                    <PillTag pillNameArray={pills}></PillTag>
                </Grid>
                <Grid container className='card-content-container'>
                    <Typography className='regular-text-medium'>
                        Data is arguably most influential in the e-commerce
                        industry. Needless to say that machine learning and
                        artificial intelligence play a crucial role in the
                        development of this domain. These technologies help
                        gather, analyse, and make sense of data regarding
                        customer habits in real-time to make suitable
                        predictions. For instance, the moment someone adds a
                        product to their cart, websites and e-commerce platforms
                        can recommend other products that the same customer is
                        most likely to buy thereafter. Machine learning and
                        artificial intelligence is also useful in anomaly
                        detection, customer care and pricing. Acadgild issues
                        several case studies that involve churn predictions,
                        sentiment analysis, customer segregation and time series
                        forecasting – all of which are crucial in e-commerce.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className='project-card outer-box'>
                <Grid container>
                    <Typography
                        variant={'h3'}
                        className='section-subtitle card-title'
                    >
                        Finance
                    </Typography>
                </Grid>
                <Divider className='card-divider' />
                <Grid container className='pill-container'>
                    <PillTag pillNameArray={pills}></PillTag>
                </Grid>
                <Grid container className='card-content-container'>
                    <Typography className='regular-text-medium'>
                        Data science relies on information from a variety of
                        sources to make effective predictions. In a
                        well-documented domain like finance, it has created
                        plenty of opportunities to understand customers and
                        deliver better products and experiences. Tasks like
                        generating a credit-score for potential customers has
                        become a lot easier. Acadgild uses case studies from
                        finance to help learners understand the practical
                        implementations of feature engineering techniques and
                        other machine learning concepts to solve real-world
                        problems.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className='project-card outer-box'>
                <Grid container>
                    <Typography
                        variant={'h3'}
                        className='section-subtitle card-title'
                    >
                        Telecommunications
                    </Typography>
                </Grid>
                <Divider className='card-divider' />
                <Grid container className='pill-container'>
                    <PillTag pillNameArray={pills}></PillTag>
                </Grid>
                <Grid container className='card-content-container'>
                    <Typography className='regular-text-medium'>
                        Machine learning is transforming the telecommunications
                        industry. It is having a significant impact in network
                        automation, and more importantly, on customer
                        experience. The technology is also reducing operational
                        costs and helping telecom companies achieve greater
                        margins with increased efficiency. Acadgild provides
                        case studies on telecommunications companies that
                        provide internet, phone, TV streaming and movie
                        streaming services. These case studies help learners
                        find models that can effectively predict customer
                        behaviour using clustering techniques and deep learning.
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} md={5} className='project-card outer-box'>
                <Grid container>
                    <Typography
                        variant={'h3'}
                        className='section-subtitle card-title'
                    >
                        Supply Chain
                    </Typography>
                </Grid>
                <Divider className='card-divider' />
                <Grid container className='pill-container'>
                    <PillTag pillNameArray={pills}></PillTag>
                </Grid>
                <Grid container className='card-content-container'>
                    <Typography className='regular-text-medium'>
                        Managing a supply chain has never been easy. Thanks to
                        machine learning, however, we can now use algorithms to
                        discover patterns in demand and other factors to ensure
                        that there is never a shortage of goods and products.
                        Algorithms deal with query data in an iterative fashion
                        and improve the accuracy of predictions greatly.
                        Acadgild covers various case studies on demand
                        forecasting, planning management, etc.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default ProjectCard;
