import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import './AboutCodingHero.scss';

const AboutSection = () => {
    return (
        <Grid container className='about-ch'>
            <Grid item xs={12} md={12}>
                <Grid container className='course-grid-row'>
                    <Typography
                        variant={'h2'}
                        className='subtitle full-width text-left'
                    >
                        About CodingHero
                    </Typography>
                </Grid>
                <Grid container className='about-ch-img-container'>
                    <img
                        className='img-fluid'
                        alt=''
                        src='/images/coding-hero/home/about-coding-hero.png'
                    ></img>
                </Grid>
                <Grid container>
                    <Box p={3}>
                        <Typography className='regular-medium'>
                            Traditional educational systems do more harm than good
                            to the creativity of children. CodingHero was founded
                            with the objective of giving kids ample opportunities to
                            unleash their creativity in the area of technology.
                            CodingHero has been founded by seasoned edtech
                            entrepreneur and is backed by very accomplished
                            investors.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default AboutSection ;
