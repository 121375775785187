import React, { useState } from 'react';

import { Grid, Select, MenuItem, Typography, Hidden } from '@material-ui/core';

import './TrainingFilter.scss';

const TrainingFilter = ({ filters }) => {
    const [activeFilter, setActiveFilter] = useState(1);

    const handleFilterClick = (e) => {
        setActiveFilter(e.target.getAttribute('data-value'));
    };
    const filterMenuItems = filters.map((filter, i) => {
        return (
            <MenuItem
                key={`training-filter-${i}`}
                value={i}
                onClick={handleFilterClick}
                data-value={i}
            >
                {filter}
            </MenuItem>
        );
    });
    return (
        <Grid container className='filter-container'>
            <Hidden smDown>
                <Typography
                    variant='h3'
                    className='section-subtitle'
                    display={'inline'}
                >
                    Mode Of Training
                </Typography>
            </Hidden>
            <Select
                className='select'
                variant={'outlined'}
                value={activeFilter}
            >
                {filterMenuItems}
            </Select>
        </Grid>
    );
};

export default TrainingFilter;
