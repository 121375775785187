import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
    Grid,
    Typography,
    TextField,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Avatar,
    Hidden
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { COUNTRIES_WITH_CODES } from '../../constants/constants';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import './PaymentPage.scss';
import {
    BatchSection,
    ListSection,
    PaymentSection
} from './sections/paymentPageSections';

const PaymentPage = (props) => {
    const courseResponse =
        (props.location.state && props.location.state.detail[0]) || {};

    const { name, email, code, phone, geolocation } =
        (props.location.state && props.location.state.userDetails) || {};

    const [state, setState] = useState({
        userDataName: name,
        userDataEmail: email,
        userDataGeolocation: geolocation,
        userDataCode: code,
        userDataPhone: phone
    });

    let selectedCode = {};

    for (let i = 0; i < COUNTRIES_WITH_CODES.length; i++) {
        if (COUNTRIES_WITH_CODES[i].phone === code) {
            selectedCode = COUNTRIES_WITH_CODES[i];
        } else {
            break;
        }
    }

    const [countryCode, setCountryCode] = useState(selectedCode);

    const handleInputChange = (event, newValue) => {
        let { name, value } = event.target;
        if (name === 'code') {
            value = newValue;
        }
        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const [accordion, setAccordion] = useState({
            expanded: 'panel1'
        }),
        [amount, setAmount] = useState({
            payableAmount: courseResponse.pricing.mrp,
            totalAmount: courseResponse.pricing.mrp,
            taxAmount: 0,
            selectedPlan: {}
        }),
        [batch, setBatch] = useState(courseResponse.batch),
        [coursePlanId, setCoursePlanId] = useState(
            props.location.state.coursePlanId
        ),
        [selectedBatch, setSelectedBatch] = useState(
            props.location.state.selectedBatch || null
        );

    const handleAccordianChange = (panel) => {
        setAccordion({
            expanded: panel || false
        });
    };

    const { expanded } = accordion;

    return (
        <Grid container className='payment-section' justify={'center'}>
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Typography variant={'h1'} className='p_heading'>
                        Enroll for <b>{courseResponse.productInfo.name}</b> in 3
                        simple steps
                    </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>

            <Grid item sm={10} md={7} xs={12} className='main-section'>
                <Grid container className='accordion-cntr'>
                    <Accordion
                        square
                        expanded={expanded === 'panel1'}
                        onChange={() => {
                            handleAccordianChange(
                                expanded == 'panel1' ? false : 'panel1'
                            );
                        }}
                    >
                        <AccordionSummary
                            className='summary'
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Avatar className=''>1</Avatar>
                            <Typography>Enter your Information</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='grid-row'>
                            <form className='full-width'>
                                <Grid container>
                                    <TextField
                                        name='userDataName'
                                        label='Enter your Name'
                                        size='medium'
                                        value={state.userDataName}
                                        required
                                        className='input-txt full-width'
                                        onChange={handleInputChange}
                                    />

                                    <TextField
                                        name='userDataEmail'
                                        label='Enter your Email'
                                        size='medium'
                                        value={state.userDataEmail}
                                        required
                                        className='input-txt full-width'
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid container>
                                    <Autocomplete
                                        className='input-txt full-width'
                                        options={COUNTRIES_WITH_CODES}
                                        value={countryCode}
                                        onChange={(event, newValue) => {
                                            event.target.name = 'code';
                                            handleInputChange(
                                                event,
                                                newValue.phone
                                            );

                                            setCountryCode(newValue);
                                        }}
                                        name='code'
                                        autoHighlight
                                        getOptionLabel={(countryCode) => {
                                            return countryCode.label
                                                ? countryCode.phone
                                                : state.userDataCode;
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option.phone} {option.label} (
                                                {option.code})
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label='Choose a country'
                                                className='country-select-border'
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                    <TextField
                                        name='userDataPhone'
                                        label='Enter your Phone'
                                        size='medium'
                                        value={state.userDataPhone}
                                        required
                                        className='input-txt full-width'
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <Grid container>
                                    <TextField
                                        name='userDataGeolocation'
                                        label='Enter your City'
                                        size='medium'
                                        value={state.userDataGeolocation}
                                        required
                                        className='input-txt full-width'
                                        onChange={handleInputChange}
                                    />
                                </Grid>
                                <br />
                                <Button
                                    variant='contained'
                                    className='btn-contained btn'
                                    onClick={() => {
                                        handleAccordianChange(
                                            expanded == 'panel2'
                                                ? false
                                                : 'panel2'
                                        );
                                    }}
                                >
                                    Proceed
                                </Button>
                            </form>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square
                        expanded={expanded === 'panel2'}
                        onChange={() => {
                            handleAccordianChange(
                                expanded == 'panel2' ? false : 'panel2'
                            );
                        }}
                    >
                        <AccordionSummary
                            className='summary'
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Avatar className=''>2</Avatar>
                            <Typography>Batch Details</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='grid-row'>
                            <BatchSection
                                course={courseResponse}
                                batch={batch}
                                selectedBatch={selectedBatch}
                                setSelectedBatch={setSelectedBatch}
                                handleAccordianChange={handleAccordianChange}
                                coursePlanId={coursePlanId}
                                setCoursePlanId={setCoursePlanId}
                            ></BatchSection>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square
                        expanded={expanded === 'panel3'}
                        onChange={() => {
                            handleAccordianChange(
                                expanded == 'panel3' ? false : 'panel3'
                            );
                        }}
                    >
                        <AccordionSummary
                            className='summary'
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Avatar className=''>3</Avatar>
                            <Typography>Payment Plans</Typography>
                        </AccordionSummary>
                        <AccordionDetails className='grid-row'>
                            <PaymentSection
                                course={courseResponse}
                                amount={amount}
                                setAmount={setAmount}
                                name={state.userDataName}
                                code={state.userDataCode}
                                phone={state.userDataPhone}
                                geolocation={state.userDataGeolocation}
                                email={state.userDataEmail}
                                selectedBatch={selectedBatch}
                                coursePlanId={coursePlanId}
                            ></PaymentSection>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
            <Hidden smDown>
                <Grid item md={1}></Grid>{' '}
            </Hidden>
            <Grid item sm={10} xs={12} md={3} className='right-section'>
                <ListSection
                    courseName={courseResponse.productInfo.name}
                    totalAmount={amount.totalAmount}
                    currency={courseResponse.pricing.currency}
                ></ListSection>
            </Grid>
        </Grid>
    );
};

export default withRouter(PaymentPage);
