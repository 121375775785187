import React from 'react';

import { Grid, Typography, Box } from '@material-ui/core';

import './MentorCard.scss';

const MentorCard = ({ mentorsData }) => {
    if (!mentorsData || !mentorsData.length) {
        return null;
    }

    const mentorsCardArray = mentorsData.map((mentor, i) => {
        return (
            <Grid
                item
                xs={12}
                sm={5}
                md={4}
                key={`mentor-${i}`}
                
            >
                <Box className='mentor-card outer-box'>
                    <Grid container className='mentor-image'>
                        <Grid item sm={1}></Grid>
                        <Grid item sm={10}>
                            <img
                                src={mentor.imageUrl}
                                alt=''
                                height='90'
                                width='90'
                            />
                            <Typography className='mentor-name' display={'block'}>
                                {mentor.name}
                            </Typography>
                        </Grid>
                        <Grid item sm={1}></Grid>
                    </Grid>
                    <Grid container>
                        <Typography className='mentor-profile-text'>
                            {mentor.description}
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
        );
    });

    return <>{mentorsCardArray}</>;
};

export default MentorCard;
