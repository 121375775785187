import React from 'react';

import { Grid } from '@material-ui/core';

import './Loader.scss';

const Loader = () => {
    return (
        <Grid className='loader'>
            <img src='/images/loader.gif' alt='' />
        </Grid>
    );
};

export default Loader;
