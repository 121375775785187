// import installed dependencies
import React, { useState, useRef } from 'react';
import {
    AppBar,
    Toolbar,
    Tab,
    Grid,
    Drawer,
    IconButton,
    Hidden,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button,
    Menu,
    MenuItem
} from '@material-ui/core';
import {
    Menu as MenuIcon,
    CancelOutlined as CancelOutlinedIcon
} from '@material-ui/icons';
import { Link } from 'react-router-dom';

// import constants
import {
    validateEmail,
    LOGIN_URL,
    SESSION_URL,
    DASHBOARD_URL
} from '../../constants/constants';

import axios from 'axios';

// import corresponding style
import './Header.scss';

const Header = (props) => {
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);
    const [loginDialogIsOpen, setLoginDialogIsOpen] = useState(false);
    const [emailErrorBool, setEmailErrorBool] = useState(false);
    const [helperTextEmail, setHelperTextEmail] = useState('');
    const [helperTextPassword, setHelperTextPassword] = useState('');
    const [passwordErrorBool, setPasswordErrorBool] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    // This functional is not useful in current perspective since we are using signin from form submission,
    // if we have to implement API based AJAX signin then this will become relevant, so not deleting it
    const setLogin = (e) => {
        const emailValue = email.current.value;
        const passwordValue = password.current.value;

        const emailError = !validateEmail(emailValue) ? 'Email is invalid' : '';
        const passwordError = !passwordValue.length ? 'Password is empty' : '';

        setHelperTextEmail(emailError);
        setHelperTextPassword(passwordError);

        emailError.length ? setEmailErrorBool(true) : setEmailErrorBool(false);
        passwordError.length
            ? setPasswordErrorBool(true)
            : setPasswordErrorBool(false);

        if (!emailError.length && !passwordError.length) {
            const params = new FormData();
            params.append('email', 'chstdnprateek01@gmail.com');
            params.append('password', 'welcome123!@#');
            //
            // TODO : API call will come here
            axios({
                method: 'post',
                url: LOGIN_URL,
                data: params
            })
                .then((res) => {
                    // window.location = `${DASHBOARD_URL}user/dashboard`;
                    window.location = `${DASHBOARD_URL}auth/signin?newWebsiteSignIn=1&${encodeURI(
                        `email=chstdnprateek01@gmail.com&password=welcome123!@#`
                    )}`;
                })
                .catch((err) => {
                    setHelperTextPassword('Email or password not correct');
                });
        }
    };

    const handleDrawerToggle = () => {
        setDrawerIsOpen(!drawerIsOpen);
    };
    const handleLoginDialogOpen = () => {
        setHelperTextEmail('');
        setEmailErrorBool(false);
        setHelperTextPassword('');
        setPasswordErrorBool(false);
        setLoginDialogIsOpen(!loginDialogIsOpen);
    };

    const email = useRef(null);
    const password = useRef(null);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const hamburgerMenu = (
        <>
            <Link className='header-tabs appbar-menu' to='#'>
                <Button
                    className='custom-menu-btn tab'
                    aria-controls='simple-menu'
                    aria-haspopup='true'
                    onClick={handleClick}
                >
                    Course
                </Button>
            </Link>
            <Menu
                className='custom-menu-dropdown tab'
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Link
                    to='/courses/data/artificial-intelligence-course-for-kids'
                    className='simple-link'
                >
                    <MenuItem onClick={handleClose}>
                        Artificial Intelligence
                    </MenuItem>
                </Link>
                <Link
                    to='/courses/data/web-and-mobile-app-development-course-for-kids'
                    className='simple-link'
                >
                    <MenuItem onClick={handleClose}>
                        Web &amp; Mobile App development
                    </MenuItem>
                </Link>
                <Link
                    to='/courses/data/design-foundation-course-for-kids'
                    className='simple-link'
                >
                    <MenuItem onClick={handleClose}>Design Foundation</MenuItem>
                </Link>
            </Menu>

            <Link className='header-tabs appbar-menu' to='#'>
                <Tab label='About' className='tab'></Tab>
            </Link>
            <Link className='header-tabs appbar-menu' to='#'>
                <Tab label='Enquire Now' className='tab'></Tab>
            </Link>
            <Link
                className='header-tabs appbar-menu'
                to='//demo.codinghero.ai'
                rel='noopener'
                target='_blank'
            >
                <Tab label='Demo' className='tab'></Tab>
            </Link>
            <Link className='header-tabs appbar-menu' to='#'>
                <Tab
                    label='Login'
                    className='tab'
                    onClick={handleLoginDialogOpen}
                ></Tab>
            </Link>
        </>
    );

    const loginDialogChildren = (
        <>
            <form action={`${DASHBOARD_URL}auth/signin`}>
                <input type='hidden' value='1' name='newWebsiteSignIn' />
                <DialogTitle>Sign in</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Sign in to your account to access
                    </DialogContentText>

                    <TextField
                        autoFocus
                        required
                        label='Email'
                        variant='outlined'
                        type='email'
                        fullWidth={true}
                        mb={4}
                        name='email'
                        className='email-field'
                        inputRef={email}
                        error={emailErrorBool}
                        helperText={helperTextEmail}
                    />
                    <TextField
                        required
                        label='Password'
                        variant='outlined'
                        type='password'
                        name='password'
                        fullWidth={true}
                        inputRef={password}
                        error={passwordErrorBool}
                        helperText={helperTextPassword}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' type='submit'>
                        Proceed
                    </Button>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={handleLoginDialogOpen}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </form>
        </>
    );

    return (
        <AppBar position='sticky' className='appbar ch-nav'>
            <Toolbar className='max-width'>
                <Grid item sm={4} xs={11}>
                    <Link to='/'>
                        <img
                            src='/images/coding-hero/ch-logo-transparent-reverse-theme.png'
                            alt='coding hero logo'
                            height='25px'
                        />
                    </Link>
                </Grid>
                <Hidden smDown>
                    <Grid item sm={8} className='text-right'>
                        {hamburgerMenu}
                    </Grid>
                </Hidden>
                <Hidden mdUp>
                    <Grid item xs={1} sm={8} className='text-right'>
                        <IconButton
                            color='inherit'
                            aria-label='Open drawer'
                            edge='start'
                            onClick={handleDrawerToggle}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Grid>
                </Hidden>
                <Drawer
                    anchor={'top'}
                    open={drawerIsOpen}
                    onClick={handleDrawerToggle}
                    className='header-drawer'
                >
                    <CancelOutlinedIcon
                        className='header-icons cancel-icon'
                        fontSize={'small'}
                        onClick={handleDrawerToggle}
                    ></CancelOutlinedIcon>
                    {hamburgerMenu}
                </Drawer>
            </Toolbar>
            <Dialog
                open={loginDialogIsOpen}
                children={loginDialogChildren}
                onClose={handleLoginDialogOpen}
                className='login-dialog'
            ></Dialog>
        </AppBar>
    );
};

export default Header;
