const panelMenu = [
    {
        label: 'All Courses',
        url: '/courses'
    },
    {
        label: 'Big Data',
        url: '/courses/big-data'
    },
    {
        label: 'Cloud Computing',
        url: '/courses/cloud-computing'
    },
    {
        label: 'Crypto',
        url: '/courses/crypto'
    },
    {
        label: 'Cyber Security',
        url: '/courses/cyber-security'
    },
    {
        label: 'IT Service Management',
        url: '/courses/it-service-management'
    },
    {
        label: 'Management',
        url: '/courses/management'
    },
    {
        label: 'Mobile App Development',
        url: '/courses/mobile-app-development'
    },
    {
        label: 'Project Management',
        url: '/courses/project-management'
    },
    {
        label: 'Quality Management',
        url: '/courses/quality-management'
    },
    {
        label: 'Software Development',
        url: '/courses/software-development'
    },
    {
        label: 'Web Development',
        url: '/courses/web-development'
    },
    {
        label: 'Web Graphic Designing',
        url: '/courses/web-graphic-designing'
    }
];
const filters = ['All', 'Online', 'Offline', 'Self Paced'];
const courseDetails = [
    {
        // existing data coming from `app/models/Course.php : 269`, underscores conerted to camelCasing
        id: 26,
        desciption: 'web-development-bundle-training-certification',
        parentName: 'Course Bundle',
        name: 'Web Development Bundle',
        courseLogo:
            'https://acadgild.com/photos/home/popular-course/analytics-for-non-programmers-training-certification.png', // data coming from DB doesn't have full url
        courseInfo: 'Online bootcamp to learn Frontend Web Development,..',
        reviews: 198,
        learners: 10052,
        rating: '3.9',
        title: '@coursename Training Certification',
        metaDescription: '',
        metaKeyword: '',
        keyPoint: '',
        courseSubtitle: '',
        offerCourse: '',
        parentDescription: '',
        courseSellingPoints: '',
        bundledStatus: 1,
        coursesContained: '2,38,6',
        hours: 240,
        projectHTMLInfo:
            'Online bootcamp to learn Frontend Web Development,...',
        freemium: 0,
        universityId: 0,
        degreeId: 0,
        courseType: 'long term',

        // more data required
        courseProgramTitle:
            '10 weeks program that prepares you to analyze data, draw insights & make data driven decisions without having to learn coding.',
        toolsYoullLearn: 'Excel VBA Macros Tableau',
        duration: '10 weeks',
        effort: '8-10 hours/week',
        highlights: [
            { codingAssignments: 200 },
            { industryCases: 3 },
            { liveMentoring: 32 },
            { realTimeProjects: 5 }
        ],
        price: '35,398',
        modeOfTraining: 'Online'
    },
    {
        // existing data coming from `app/models/Course.php : 269`, underscores conerted to camelCasing
        id: 26,
        desciption: 'web-development-bundle-training-certification',
        parentName: 'Course Bundle',
        name: 'Web Development Bundle',
        courseLogo:
            'https://acadgild.com/photos/home/popular-course/analytics-for-non-programmers-training-certification.png', // data coming from DB doesn't have full url
        courseInfo: 'Online bootcamp to learn Frontend Web Development,..',
        reviews: 198,
        learners: 10052,
        rating: '3.9',
        title: '@coursename Training Certification',
        metaDescription: '',
        metaKeyword: '',
        keyPoint: '',
        courseSubtitle: '',
        offerCourse: '',
        parentDescription: '',
        courseSellingPoints: '',
        bundledStatus: 1,
        coursesContained: '2,38,6',
        hours: 240,
        projectHTMLInfo:
            'Online bootcamp to learn Frontend Web Development,...',
        freemium: 0,
        universityId: 0,
        degreeId: 0,
        courseType: 'long term',

        // more data required
        courseProgramTitle:
            '10 weeks program that prepares you to analyze data, draw insights & make data driven decisions without having to learn coding.',
        toolsYoullLearn: 'Excel VBA Macros Tableau',
        duration: '10 weeks',
        effort: '8-10 hours/week',
        highlights: [
            { codingAssignments: 200 },
            { industryCases: 3 },
            { liveMentoring: 32 },
            { realTimeProjects: 5 }
        ],
        price: '35,398',
        modeOfTraining: 'Online'
    },
    {
        // existing data coming from `app/models/Course.php : 269`, underscores conerted to camelCasing
        id: 26,
        desciption: 'web-development-bundle-training-certification',
        parentName: 'Course Bundle',
        name: 'Web Development Bundle',
        courseLogo:
            'https://acadgild.com/photos/home/popular-course/analytics-for-non-programmers-training-certification.png', // data coming from DB doesn't have full url
        courseInfo: 'Online bootcamp to learn Frontend Web Development,..',
        reviews: 198,
        learners: 10052,
        rating: '3.9',
        title: '@coursename Training Certification',
        metaDescription: '',
        metaKeyword: '',
        keyPoint: '',
        courseSubtitle: '',
        offerCourse: '',
        parentDescription: '',
        courseSellingPoints: '',
        bundledStatus: 1,
        coursesContained: '2,38,6',
        hours: 240,
        projectHTMLInfo:
            'Online bootcamp to learn Frontend Web Development,...',
        freemium: 0,
        universityId: 0,
        degreeId: 0,
        courseType: 'long term',

        // more data required
        courseProgramTitle:
            '10 weeks program that prepares you to analyze data, draw insights & make data driven decisions without having to learn coding.',
        toolsYoullLearn: 'Excel VBA Macros Tableau',
        duration: '10 weeks',
        effort: '8-10 hours/week',
        highlights: [
            { codingAssignments: 200 },
            { industryCases: 3 },
            { liveMentoring: 32 },
            { realTimeProjects: 5 }
        ],
        price: '35,398',
        modeOfTraining: 'Online'
    },
    {
        // existing data coming from `app/models/Course.php : 269`, underscores conerted to camelCasing
        id: 26,
        desciption: 'web-development-bundle-training-certification',
        parentName: 'Course Bundle',
        name: 'Web Development Bundle',
        courseLogo:
            'https://acadgild.com/photos/home/popular-course/analytics-for-non-programmers-training-certification.png', // data coming from DB doesn't have full url
        courseInfo: 'Online bootcamp to learn Frontend Web Development,..',
        reviews: 198,
        learners: 10052,
        rating: '3.9',
        title: '@coursename Training Certification',
        metaDescription: '',
        metaKeyword: '',
        keyPoint: '',
        courseSubtitle: '',
        offerCourse: '',
        parentDescription: '',
        courseSellingPoints: '',
        bundledStatus: 1,
        coursesContained: '2,38,6',
        hours: 240,
        projectHTMLInfo:
            'Online bootcamp to learn Frontend Web Development,...',
        freemium: 0,
        universityId: 0,
        degreeId: 0,
        courseType: 'long term',

        // more data required
        courseProgramTitle:
            '10 weeks program that prepares you to analyze data, draw insights & make data driven decisions without having to learn coding.',
        toolsYoullLearn: 'Excel VBA Macros Tableau',
        duration: '10 weeks',
        effort: '8-10 hours/week',
        highlights: [
            { codingAssignments: 200 },
            { industryCases: 3 },
            { liveMentoring: 32 },
            { realTimeProjects: 5 }
        ],
        price: '35,398',
        modeOfTraining: 'Online'
    }
];

export { panelMenu, filters, courseDetails };
