import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Tab, Tabs, Grid, Select, MenuItem } from '@material-ui/core';

import './CategoryPanel.scss';

const CategoryPanelLeft = ({ panelMenu }) => {
    const [activeTab, setActiveTab] = useState(0);
    const history = useHistory();

    const handleTabClick = (e) => {
        e.preventDefault();
        const location = e.target.closest('a').getAttribute('href');
        handleActiveTab(location);
        history.push(location);
    };

    useEffect(() => {
        handleActiveTab(history.location.pathname);
    }, []);

    const handleActiveTab = (loc) => {
        for (let i = 0; i < panelMenu.length; i++) {
            if (loc === panelMenu[i].url) {
                setActiveTab(i);
                return;
            }
        }
    };

    const tabDisplayItems = panelMenu.map((item, i) => {
        return (
            <Tab
                className='course-link'
                component={'a'}
                label={item.label}
                key={`tabs-key-${i}`}
                href={item.url}
                onClick={handleTabClick}
            ></Tab>
        );
    });

    return (
        <Grid container className='category-panel left-panel'>
            <Tabs
                value={activeTab}
                orientation={'vertical'}
                className='vertical-tabs'
            >
                {tabDisplayItems}
            </Tabs>
        </Grid>
    );
};

const CategoryPanelTop = ({ panelMenu }) => {
    const [activeMenuItem, setActiveMenuItem] = useState(0);
    const history = useHistory();

    const handleMenuItemClick = (e) => {
        const location = e.target.getAttribute('data-link');
        handleActiveMenuItem(location);
        history.push(location);
    };

    useEffect(() => {
        handleActiveMenuItem(history.location.pathname);
    }, []);

    const handleActiveMenuItem = (loc) => {
        for (let i = 0; i < panelMenu.length; i++) {
            if (loc === panelMenu[i].url) {
                setActiveMenuItem(i);
                return;
            }
        }
    };

    const selectDisplayItems = panelMenu.map((item, i) => {
        return (
            <MenuItem
                className='course-option'
                key={`MenuItems-key-${i}`}
                data-link={item.url}
                onClick={handleMenuItemClick}
                value={i}
            >
                {item.label}
            </MenuItem>
        );
    });
    return (
        <Grid item xs={12} md={6} className='category-panel top-panel'>
            <Select
                className='select'
                label='Courses'
                variant='outlined'
                value={activeMenuItem}
            >
                {selectDisplayItems}
            </Select>
        </Grid>
    );
};

export { CategoryPanelLeft, CategoryPanelTop };
