import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';

import {
    ArrowBackIos as ArrowBackIosIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Cancel as CancelIcon,
    CheckCircleOutline as CheckCircleOutlineIcon
} from '@material-ui/icons';

import { getCurrencySymbol } from '../../../../constants/constants';

import './CourseComparisonSection.scss';

const CourseComparisonSection = ({ coursePrice, currency }) => {
    const currencySymbol = getCurrencySymbol(currency);
    return (
        <Hidden xsDown>
            <Grid container className='course-grid-row comparison-section'>
                <Grid item xs={12} md={12} className='course-grid-row'>
                    <Grid container className='course-grid-row'>
                        <Typography
                            variant={'h2'}
                            className='subtitle full-width text-center'
                        >
                            The CodingHero Advantage
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        className='course-grid-row grid-row-content'
                    >
                        <Grid
                            item
                            xs={4}
                            md={4}
                            className='comparision-parameters'
                        >
                            <Grid
                                container
                                className='course-grid-row merge-bg cell'
                            ></Grid>
                            <Grid
                                container
                                className='course-grid-row cell left-top-round'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    Duration
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row cell'>
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    Live Sessions
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row cell'>
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    Assignments
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row cell'>
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    Projects
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row cell'>
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    Gamified Dashboard
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row cell'>
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    Lifelong Access To Dashboard
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row cell'>
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    24 x 7 Technical Support
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row cell'>
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    Study Counsellor's Guidance
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell left-bottom-round'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    Portfolio Development
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row merge-bg'
                            ></Grid>
                        </Grid>
                        <Grid item xs={3} md={4} className='codinghero-score'>
                            <Grid
                                container
                                className='course-grid-row class-head cell left-top-round right-top-round'
                            >
                                <Grid>
                                    <ArrowBackIosIcon
                                        fontSize={'small'}
                                        className='blue'
                                    />
                                    <Typography
                                        className='regular-text large bold blue'
                                        display={'inline'}
                                    >
                                        CODING
                                    </Typography>
                                    <Typography
                                        className='regular-text large bold orange'
                                        display={'inline'}
                                    >
                                        HERO
                                    </Typography>
                                    <ArrowForwardIosIcon
                                        fontSize={'small'}
                                        className='blue'
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    16 Weeks
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell even'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    100 Hours
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    NA
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell even'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    8+
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CheckCircleOutlineIcon className='tick-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell even'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CheckCircleOutlineIcon className='tick-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CheckCircleOutlineIcon className='tick-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell even'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CheckCircleOutlineIcon className='tick-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CheckCircleOutlineIcon className='tick-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row'>
                                <Typography
                                    variant={'h2'}
                                    className='subtitle large'
                                >
                                    {`${currencySymbol} ${
                                        coursePrice
                                            ? coursePrice.toLocaleString()
                                            : ''
                                    }`}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} md={3} className='others-score'>
                            <Grid
                                container
                                className='course-grid-row class-head cell'
                            >
                                <Typography
                                    className='heading-level-4 large'
                                    display={'block'}
                                    variant={'h4'}
                                >
                                    Other Virtual Live Classes
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    16 Weeks
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell even'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    100 Hours
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    NA
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell even'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    8+
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CancelIcon className='cancel-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell even'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CheckCircleOutlineIcon className='tick-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CancelIcon className='cancel-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell even'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CancelIcon className='cancel-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='course-grid-row cell odd'
                            >
                                <Typography
                                    variant={'h4'}
                                    className='heading-level-4 large'
                                >
                                    <CancelIcon className='cancel-round-icon' />
                                </Typography>
                            </Grid>
                            <Grid container className='course-grid-row'>
                                <Typography
                                    variant={'h2'}
                                    className='subtitle large'
                                >{`${currencySymbol} ${(
                                    coursePrice * 5
                                ).toLocaleString()}`}</Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={1}
                            md={1}
                            className='right-grid-bg right-top-round right-bottom-round'
                        ></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Hidden>
    );
};

export default CourseComparisonSection;
