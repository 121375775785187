import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import './Ribbon.scss';

const RibbonPage = ({ handleClickOpen }) => {
    return (
        <Grid container className='ribbons'>
            <Typography className='ribbon-text'>
                Unleash hidden genius in your child
            </Typography>
            <Button className='enquire-btn' onClick={handleClickOpen}>
                Enquire Now
            </Button>
        </Grid>
    );
};
export { RibbonPage };
