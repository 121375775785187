import React, { useState, useEffect } from 'react';
import { Grid, Typography, Select, MenuItem } from '@material-ui/core';

import AccordianContainer from '../acordian-container/AccordianContainer';

import './Faqs.scss';

const Faqs = ({courseName, sectionTitle, faqOptions, faqData, initialValue }) => {
    const [activeOption, setActiveOption] = useState(
            initialValue ? initialValue : 0
        ),
        [activeFAQData, setActiveFAQData] = useState();
        
    const setFaqListData = (optionName) => {
        if (!optionName) {
            optionName = faqOptions[0];
        }
        const faq = [];
        for (let i = 0; i < faqData.length; i++) {
            if (faqData[i].category === optionName) {
                faq.push({
                    title: faqData[i].title,
                    listData: faqData[i].listData
                });
            }
        }
        setActiveFAQData(faq);
    };

    const handleOptionClick = (e) => {
        setFaqListData(e.target.getAttribute('data-name'));
        setActiveOption(e.target.getAttribute('data-value'));
    };
    const optionMenuItems = faqOptions.map((option, i) => {
        return (
            <MenuItem
                key={`training-option-${i}`}
                value={i}
                onClick={handleOptionClick}
                data-value={i}
                data-name={option}
            >
                {option}
            </MenuItem>
        );
    });

    useEffect(() => {
        setFaqListData();
    }, [faqData]);

    return (
        <Grid container className='faq-section'>
            <Grid container className='faq-title faq-container grid-row'>
                <Typography
                    variant={'h2'}
                    className='subtitle full-width text-left'
                >
                    {sectionTitle}
                </Typography>
            </Grid>
            <Grid
                container
                className='faq-filter faq-container grid-row-content'
            >
                <Select
                    className='select'
                    variant={'outlined'}
                    value={activeOption}
                >
                    {optionMenuItems}
                </Select>
            </Grid>
            <Grid container className='grid-row'>
                <AccordianContainer accordianData={activeFAQData} dot={false} />
            </Grid>
        </Grid>
    );
};

export default Faqs;
