import React from 'react';
import { List, ListItem, ListItemIcon, Typography } from '@material-ui/core';

import { FiberManualRecord as FiberManualRecordIcon } from '@material-ui/icons';

import './AccordianList.scss';

const AccordianList = ({ listData, dot }) => {
    let list;

    if (dot) {
        list = listData.map((item, i) => {
            if (item.length) {
                return (
                    <ListItem key={`list-item-${item}-${i}`}>
                        <ListItemIcon>
                            <FiberManualRecordIcon
                                className='list-icon-dot'
                                fontSize={'small'}
                            ></FiberManualRecordIcon>
                        </ListItemIcon>
                        <Typography className='regular-text large'>
                            {item}
                        </Typography>
                    </ListItem>
                );
            }
        });
    } else {
        list = listData.map((item, i) => {
            if (item.length) {
                return (
                    <ListItem key={`list-item-${item}-${i}`}>
                        <ListItemIcon></ListItemIcon>
                        <Typography className='regular-text large'>
                            {item}
                        </Typography>
                    </ListItem>
                );
            }
        });
    }

    return <List>{list}</List>;
};

export default AccordianList;
