import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import { CoursePaymentCard as PaymentCard } from '../../../../components/card/Card';

import './PaymentSection.scss';

const PaymentSection = ({ paymentPlans, price }) => {
    if (!paymentPlans.length) {
        return null;
    }

    const paymentCards = paymentPlans.map((plan, i) => {
        return (
            <PaymentCard plan={plan} amount={price} key={`payment-card-${i}`} />
        );
    });

    return (
        <Box className="full-width">
            <Grid container className='payment-section grid-row'>
                <Grid item xs={12} md={12}>
                    <Grid container className='course-grid-row'>
                        <Typography
                            variant={'h2'}
                            className='subtitle text-left full-width'
                        >
                            Payment Plans
                        </Typography>
                    </Grid>
                    <Grid
                        container
                        className='grid-row course-grid-content'
                        justify={'center'}
                    >
                        {paymentCards}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default PaymentSection;
