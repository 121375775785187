import React from 'react';
import { Grid, Typography, Hidden } from '@material-ui/core';

import './TopRibbonSection.scss';

const TopRibbonSection = () => {
    return (
        <Grid container className='top-ribbon'>
            <Hidden smDown>
                <Grid item xs={2} md={1} className='certificate'>
                    <img
                        src='/images/CertificateComposite.png'
                        alt=''
                        height='150px'
                    />
                </Grid>
            </Hidden>
            <Grid item md={7} xs={12}>
                <Grid container className='grid-row'>
                    <Grid
                        item
                        md={3}
                        sm={6}
                        xs={6}
                        className='text-center grid-row top-ribbon-item no-border'
                    >
                        <img
                            src='/images/course-page/fun.png'
                            alt=''
                            //height='35px'
                            width='70px'
                        />
                        <Typography variant="h6">
                            Live Online Sessions
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        sm={6}
                        xs={6}
                        className='text-center grid-row top-ribbon-item no-border'
                    >
                        <img
                            src='/images/course-page/friendly-mentor.png'
                            alt=''
                            //height='35px'
                            width='70px'
                        />
                        <Typography variant="h6">
                            Child-Friendly Mentors
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        sm={6}
                        xs={6}
                        className='text-center grid-row top-ribbon-item no-border'
                    >
                        <img
                            src='/images/course-page/creativity.png'
                            alt=''
                            //height='35px'
                            width='70px'
                        />
                        <Typography variant="h6" >
                            Great Support
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={3}
                        sm={6}
                        xs={6}
                        className='text-center grid-row top-ribbon-item no-border'
                    >
                        <img
                            src='/images/course-page/innovator.png'
                            alt=''
                            //height='35px'
                            width='70px'
                        />
                        <Typography variant="h6" >
                            Structured Content
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TopRibbonSection;
