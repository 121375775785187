import React from 'react';
import { Grid, Typography, Hidden, Divider, Box } from '@material-ui/core';

import Carousel from 'react-material-ui-carousel';
import { FormatQuote as FormatQuoteIcon } from '@material-ui/icons';
import Rating from '@material-ui/lab/Rating';

import './ReviewsSection.scss';

const ReviewsSection = ({ reviewsData }) => {
    if (!reviewsData.length) {
        return null;
    }

    const carouselReviewItem = reviewsData.map((review, i) => {
        return (
            <Grid container justify={'center'} key={`carouselReviewItem-${i}`}>
                <Grid
                    item
                    xs={4}
                    sm={2}
                    justify={'center'}
                    className='text-center'
                >
                    <Grid container className='user-pic-container'>
                        <img
                            alt=''
                            src={review.imageUrl}
                            height='100px'
                            width='100px'
                            display='block'
                        />
                    </Grid>
                    <Typography className='regular-text bold text-center'>
                        {review.name}
                    </Typography>
                    <Typography className='regular-text italic text-center'>
                        {review.designation}
                    </Typography>
                </Grid>
                <Grid item sm={8}>
                    <FormatQuoteIcon></FormatQuoteIcon>
                    <Typography variant={'caption'} className='regular-text'>
                        {review.review}
                    </Typography>
                </Grid>
            </Grid>
        );
    });

    const carouselReviewCard = reviewsData.map((review, i) => {
        return (
            <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    justify={'center'}
                    className='text-center'
                    display="flex"
                    flex-direction="column"
                >
                    <Box className='review-container'>
                        <Box className='user-pic-container' m={2}>
                            <img
                                alt=''
                                src={review.imageUrl}
                                height='100px'
                                width='100px'
                                display='block'
                            />
                        </Box>
                        <Box m={2} className='review-text'>
                            {review.review}
                        </Box>
                        <Box m={2} className='review-parent-name'>
                            {review.name}
                        </Box>
                        <Box m={1}>
                            <Rating name="read-only" value={5} readOnly />
                        </Box>
                    </Box>


            </Grid>
        )
    })

    return (
        <Box className="full-width">
            <Grid container className='reviews-section max-width'>
                <Grid item xs={12} md={9}>
                    <Grid container className='course-grid-row'>
                        <Grid item xs={12} md={12}>
                            <Grid container item className='course-grid-row'>
                                <Typography
                                    variant={'h2'}
                                    className='subtitle full-width text-left'
                                >
                                    What Parents Are Saying
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                className='review-carousel-container course-grid-content'
                            >
                                {carouselReviewCard}
                                {/* <Carousel navButtonsAlwaysVisible={false} navButtonsAlwaysInvisible={true}>
                                    {carouselReviewCard}
                                </Carousel> */}
                            </Grid>
                        </Grid>
                        <Hidden xsUp>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                md={3}
                                className='ratings-section'
                            >
                                <Grid container>
                                    <Typography
                                        variant={'h3'}
                                        className='section-subtitle large'
                                    >
                                        Loved By Users
                                    </Typography>
                                </Grid>
                                <Grid container>
                                    <Typography variant={'h4'} display={'inline'}>
                                        4.4/5
                                    </Typography>
                                    <Typography
                                        variant={'caption'}
                                        className='regular-text user-ratings'
                                        display={'inline'}
                                    >
                                        (200+ REVIEWS)
                                    </Typography>
                                    <Grid container>
                                        <img
                                            src='/images/course-page/g1.jpg'
                                            alt='google'
                                            height='20px'
                                            width='70px'
                                        />
                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container>
                                    <Typography variant={'h4'} display={'inline'}>
                                        4.4/5
                                    </Typography>
                                    <Typography
                                        variant={'caption'}
                                        className='regular-text user-ratings'
                                        display={'inline'}
                                    >
                                        (200+ REVIEWS)
                                    </Typography>
                                    <Grid container>
                                        <img
                                            src='/images/course-page/g1.jpg'
                                            alt='google'
                                            height='20px'
                                            width='70px'
                                        />
                                    </Grid>
                                </Grid>
                                <Divider></Divider>
                                <Grid container>
                                    <Typography variant={'h4'} display={'inline'}>
                                        4.4/5
                                    </Typography>
                                    <Typography
                                        variant={'caption'}
                                        className='regular-text user-ratings'
                                        display={'inline'}
                                    >
                                        (200+ REVIEWS)
                                    </Typography>
                                    <Grid container>
                                        <img
                                            src='/images/course-page/g1.jpg'
                                            alt='google'
                                            height='20px'
                                            width='70px'
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ReviewsSection;
