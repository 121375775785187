import React, { useState, useRef } from 'react';
import {
    Grid,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Collapse
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
    HomeCard,
    HomeCourseCard,
    MentorCard
} from '../../components/card/Card';
import { AboutSection } from '../../sections/about-codinghero/AboutCodingHero';
import { RibbonPage } from '../../sections/home-ribbon/Ribbon';
import StudiesSection from '../../sections/section-studies/StudiesSection';

import { MAIL_URL } from '../../constants/constants';
import axios from 'axios';

import './HomePage.scss';
const HomePage = () => {
    const [open, setOpen] = useState(false);
    const [age, setAge] = useState();
    const [course, setCourse] = useState();
    const [courseId, setCourseId] = useState();
    const [alertOpen, setAlertOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEnquiryFormSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.target);
        const dataObj = {};
        for (var [key, value] of data.entries()) {
            dataObj[key] = value;
        }
        dataObj.course = course;
        dataObj.age = age;
        dataObj.course_id = courseId;

        axios.post(MAIL_URL, dataObj).catch((err) => {
            console.log(err);
        });

        setAlertOpen(true);
        window.gtag_report_conversion(window.location.href);
    };

    const ageHandleChange = (event) => {
        setAge(event.target.value);
    };

    const courseHandleChange = (event) => {
        setCourse(event.target.value);
    };
    const mentorsData = [
        {
            description:
                'Full Stack Development Expert and Instructor | Content Creator | YouTuber | Speaker & Mentor',
            designation: 'Codinghero Mentor',
            imageUrl:
                'https://ch-website.s3-ap-southeast-1.amazonaws.com/shalini.png',
            linkedinProfile: 'https://www.linkedin.com/in/asjankit/',
            name: 'Shalini Mittal'
        },
        {
            description:
                'Data Scientist (Freelancer) and Data Science Mentor and Content Creator',
            designation: 'Codinghero Mentor',
            imageUrl:
                'https://ch-website.s3-ap-southeast-1.amazonaws.com/deepali.png',
            linkedinProfile: 'https://www.linkedin.com/in/insoucyant/',
            name: 'Deepali Chandel'
        },
        {
            description:
                'Associate Specialist Technology - (AI & ML-R&D) at Synechron & Data Science Mentor',
            designation: 'Codinghero Mentor ',
            imageUrl:
                'https://ch-website.s3-ap-southeast-1.amazonaws.com/krishna.png',
            linkedinProfile: 'https://www.linkedin.com/in/arjun-tewari/',
            name: 'G. Krishna Mali'
        }
    ];
    return (
        <Grid container className='home-page'>
            <Collapse in={alertOpen} className='full-width alert-collapse'>
                <Alert
                    severity='success'
                    onClose={() => {
                        setAlertOpen(false);
                    }}
                >
                    Thank you for enquiring, our customer care representative
                    will get back to you soon
                </Alert>
            </Collapse>
            <Grid container className='home-page-row banner'>
                <Grid container className='banner'>
                    <Grid item xs={12} sm={6} className='subheading'>
                        <Typography variant={'h1'} className='banner-h1'>
                            Give your children an unfair advantage.
                            <br />
                            Prepare them for future, now!
                        </Typography>
                        <Typography className='banner-p'>
                            Live online coding & design classes from leading
                            mentors and experts
                        </Typography>
                        <br />
                        <Button
                            className='enquire-btn'
                            onClick={handleClickOpen}
                        >
                            Enquire Now
                        </Button>

                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby='form-dialog-title'
                        >
                            <Grid container className='custom-popup'>
                                <Grid item xs={12}>
                                    <Typography
                                        onClick={handleClose}
                                        display={'block'}
                                        className='close'
                                    >
                                        X
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    md={6}
                                    className='img-cntr text-center'
                                >
                                    <img
                                        src='/images/coding-hero/coding-class.png'
                                        alt=''
                                        className='img-fluid'
                                    ></img>
                                    <Typography className='regular-text medium bold'>
                                        Give Your child an unfair advantage.
                                    </Typography>
                                    <Typography className='regular-text'>
                                        Prepare him for future, now!
                                    </Typography>
                                </Grid>
                                <Grid item md={6}>
                                    <form onSubmit={handleEnquiryFormSubmit}>
                                        <input type='hidden' value={courseId} />
                                        <DialogTitle
                                            id='form-dialog-title'
                                            className='regular-text xxl'
                                        >
                                            Gift your child a skill for life
                                        </DialogTitle>
                                        <DialogContent>
                                            <FormControl fullWidth>
                                                <TextField
                                                    required
                                                    variant='outlined'
                                                    className='input-text'
                                                    label='Name'
                                                    name='name'
                                                />
                                                <TextField
                                                    required
                                                    variant='outlined'
                                                    className='input-text'
                                                    label='Phone number'
                                                    name='phone'
                                                />
                                                <TextField
                                                    required
                                                    variant='outlined'
                                                    className='input-text'
                                                    label='Email'
                                                    type={'email'}
                                                    name='email'
                                                />
                                            </FormControl>
                                            <FormControl
                                                fullWidth
                                                variant='outlined'
                                            >
                                                <InputLabel id='courselbl'>
                                                    Course Interested In
                                                </InputLabel>
                                                <Select
                                                    labelId='courselbl'
                                                    id='courseDropdown'
                                                    value={course}
                                                    onChange={
                                                        courseHandleChange
                                                    }
                                                    label='Course Interested In'
                                                    className='input-select'
                                                >
                                                    <MenuItem
                                                        value={1}
                                                        data-id=''
                                                    >
                                                        Artificial Intelligence
                                                        for kids
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={2}
                                                        data-id=''
                                                    >
                                                        Web & Mobile App
                                                        Development
                                                    </MenuItem>
                                                    <MenuItem
                                                        value={3}
                                                        data-id=''
                                                    >
                                                        Design Foundation
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl
                                                fullWidth
                                                variant='outlined'
                                            >
                                                <InputLabel id='agelbl'>
                                                    Age
                                                </InputLabel>
                                                <Select
                                                    labelId='agelbl'
                                                    id='ageDropdown'
                                                    value={age}
                                                    onChange={ageHandleChange}
                                                    label='Age'
                                                >
                                                    <MenuItem value={5}>
                                                        5 years
                                                    </MenuItem>
                                                    <MenuItem value={6}>
                                                        6 years
                                                    </MenuItem>
                                                    <MenuItem value={7}>
                                                        7 years
                                                    </MenuItem>
                                                    <MenuItem value={8}>
                                                        8 years
                                                    </MenuItem>
                                                    <MenuItem value={9}>
                                                        9 years
                                                    </MenuItem>
                                                    <MenuItem value={10}>
                                                        10 years
                                                    </MenuItem>
                                                    <MenuItem value={11}>
                                                        11 years
                                                    </MenuItem>
                                                    <MenuItem value={12}>
                                                        12 years
                                                    </MenuItem>
                                                    <MenuItem value={13}>
                                                        13 years
                                                    </MenuItem>
                                                    <MenuItem value={14}>
                                                        14 years
                                                    </MenuItem>
                                                    <MenuItem value={15}>
                                                        15 years
                                                    </MenuItem>
                                                    <MenuItem value={16}>
                                                        16 years
                                                    </MenuItem>
                                                    <MenuItem value={17}>
                                                        17 years
                                                    </MenuItem>
                                                    <MenuItem value={18}>
                                                        18 years
                                                    </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </DialogContent>
                                        <DialogActions className='justify-content-start'>
                                            <Button
                                                color='primary'
                                                className='btn-primary'
                                                type='submit'
                                            >
                                                Submit
                                            </Button>
                                        </DialogActions>
                                    </form>
                                </Grid>
                            </Grid>
                        </Dialog>
                    </Grid>
                    <Grid item sm={6} className='header'></Grid>
                </Grid>
            </Grid>

            {/* For Cards in home Page */}
            <Grid container className='section-gutter'>
                <HomeCard></HomeCard>
            </Grid>
            {/* For Cards in home Page end */}

            <Grid container className='home-page-row'>
                <StudiesSection />
            </Grid>

            <Grid container className='section-gutter px-7'>
                <HomeCourseCard></HomeCourseCard>
            </Grid>
            {/* For Cards in home Page end */}

            <Grid container className='home-page-row develop-section'>
                <Grid container className='learning-attributes'>
                    <Grid container className='attributes-container'>
                        <Typography variant={'h2'} className='heading'>
                            Kids Benefit By Developing
                        </Typography>
                    </Grid>
                    <Grid container className='attributes-container'>
                        <Grid className='attributes-text-container'>
                            <Grid className='bg-blue'>
                                <img
                                    className='img-fluid'
                                    src='/images/coding-hero/home/thinking.png'
                                    alt='learning attributes'
                                    height='120px'
                                    width='120px'
                                />
                            </Grid>
                            <Typography>
                                Computational <br /> Thinking
                            </Typography>
                        </Grid>
                        <Grid className='attributes-text-container'>
                            <Grid className='bg-blue'>
                                <img
                                    src='/images/coding-hero/home/learn.png'
                                    alt='learning attributes'
                                    height='120px'
                                    width='120px'
                                />
                            </Grid>
                            <Typography>
                                Logical <br /> Reasoning
                            </Typography>
                        </Grid>
                        <Grid className='attributes-text-container'>
                            <Grid className='bg-blue'>
                                <img
                                    src='/images/coding-hero/home/thinking.png'
                                    alt='learning attributes'
                                    height='120px'
                                    width='120px'
                                />
                            </Grid>
                            <Typography>
                                Algorithimic <br /> Thinking
                            </Typography>
                        </Grid>
                        <Grid className='attributes-text-container'>
                            <Grid className='bg-blue'>
                                <img
                                    src='/images/coding-hero/home/problem-solving.png'
                                    alt='learning attributes'
                                    height='120px'
                                    width='120px'
                                />
                            </Grid>
                            <Typography>
                                Problem <br /> Solving Skills
                            </Typography>
                        </Grid>
                        <Grid className='attributes-text-container'>
                            <Grid className='bg-blue'>
                                <img
                                    src='/images/coding-hero/home/creativity.png'
                                    alt='learning attributes'
                                    height='120px'
                                    width='120px'
                                />
                            </Grid>
                            <Typography>Creativity</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid container className='home-page-row blue-ribbon'>
                <RibbonPage handleClickOpen={handleClickOpen}></RibbonPage>
            </Grid>

            {mentorsData.length ? (
                <Grid container className='home-page-row mentor-section'>
                    <Grid container>
                        <Typography variant={'h2'} className='heading'>
                            Meet Our Mentors{' '}
                        </Typography>
                    </Grid>
                    <Grid container>
                        <Typography className='text-center'>
                            All our mentors go through stringent selection and
                            onboarding process. <br /> You can be sure your
                            child is in safe hands.
                        </Typography>
                    </Grid>

                    <Grid container className='mt-5'>
                        <MentorCard mentorsData={mentorsData}></MentorCard>
                    </Grid>
                </Grid>
            ) : null}

            <Grid container className='home-page-row about-ch'>
                <AboutSection></AboutSection>
            </Grid>

            <Grid container className='home-page-row'>
                <RibbonPage handleClickOpen={handleClickOpen}></RibbonPage>
            </Grid>
        </Grid>
    );
};
export default HomePage;
