import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import './StudiesSection.scss';

const StudiesSection = () => {
    return (
        <Grid container className='studies-section'>
            <Grid container className='course-grid-row'>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={10}>
                    <Typography variant={'h2'} className='header'>
                        Why Teach Coding &amp; Design to Kids?
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className='grid-row-content'>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Typography variant={'h2'} className='f-14'>
                            Studies have determined that kids between the ages
                            of 5 and 15 have high creativity and energy.
                        </Typography>
                        <img
                            className='img'
                            src='/images/coding-hero/home/bar-graph.png'
                            alt=''
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container>
                        <Typography variant={'h2'} className='f-14'>
                            If this creativity and energy can be channeled in
                            right direction, they can be turned into creative
                            geniuses.
                        </Typography>
                        <img
                            className='img'
                            src='/images/coding-hero/home/venn-diagram.png'
                            alt=''
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StudiesSection;
