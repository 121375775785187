import React from 'react';
import { Typography } from '@material-ui/core';

import './PillTag.scss';

const PillTag = ({ pillNameArray }) => {
    const pills = pillNameArray.map((pillName, i) => {
        return (
            <Typography
                variant={'h4'}
                className='heading-level-4 tag'
                display={'inline'}
                key={`pill-tag-${i}`}
            >
                {pillName}
            </Typography>
        );
    });

    return <>{pills}</>;
};

export default PillTag;
