import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';

import { isObjEmpty, getCurrencySymbol } from '../../../constants/constants';
import './CoursePaymentCard.scss';

const CoursePaymentCard = ({
    plan,
    amount,
    showDescription,
    setAmountPlanObj,
    amountPlanObj,
    activeClassName,
    customClickAction
}) => {
    if (isObjEmpty(plan)) {
        return null;
    }

    if (!activeClassName) {
        activeClassName = '';
    }

    if (!customClickAction) {
        customClickAction = () => {};
    }

    let cardData = null;

    switch (plan.planType) {
        case 'upfront':
            cardData = (
                <Grid container>
                    <Grid item>
                        <Typography className='regular-text bold xl-2 full-width'>
                            Upfront Payment
                        </Typography>
                        <Typography className='regular-text xl-2 bold'>
                            {getCurrencySymbol(amount.currency) +
                                amount.price.toLocaleString()}
                        </Typography>
                        <Typography className='regular-text'>
                            (Inclusive of all taxes)
                        </Typography>
                        {showDescription ? (
                            <Typography className='regular-text large bold'>
                                Pay Now{' '}
                                {getCurrencySymbol(amount.currency) +
                                    amount.price.toLocaleString()}
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>
            );
            break;

        default:
            const payDay = [];
            for (let i = 1; i < plan.collectionCycle.length; i++) {
                let instalmentDate = new Date(
                    new Date().getTime() +
                        plan.collectionCycle[i].days * 86400000
                );
                payDay.push(
                    <Typography
                        className='regular-text bold full-width'
                        key={`payment-card-${i}`}
                    >
                        {`${instalmentDate.getDate()}/${
                            instalmentDate.getMonth() + 1
                        }/${instalmentDate.getFullYear()}  :  
                        ${
                            getCurrencySymbol(amount.currency) +
                            (
                                parseInt(
                                    amount.price *
                                        plan.collectionCycle[i].instalmentSize
                                ) / 100
                            ).toLocaleString()
                        }`}
                    </Typography>
                );
            }
            cardData = (
                <Grid container>
                    <Grid item>
                        <Typography className='regular-text xl-2 bold'>
                            Pay as you go
                        </Typography>
                        <Typography className='regular-text medium'>
                            Lowest EMI starting at
                        </Typography>
                        <Typography className='regular-text xl-2 bold full-width'>
                            {getCurrencySymbol(amount.currency) +
                                (
                                    parseInt(
                                        amount.price *
                                            plan.collectionCycle[0]
                                                .instalmentSize
                                    ) / 100
                                ).toLocaleString()}
                        </Typography>
                        <Typography className='regular-text medium'>
                            {`Remaining in ${
                                plan.collectionCycle.length - 1
                            } Instalment(s)`}
                        </Typography>
                        {showDescription ? (
                            <Grid container>
                                <Typography className='regular-text large bold'>
                                    Pay Now{' '}
                                    {getCurrencySymbol(amount.currency) +
                                        (
                                            parseInt(
                                                amount.price *
                                                    plan.collectionCycle[0]
                                                        .instalmentSize
                                            ) / 100
                                        ).toLocaleString()}
                                </Typography>
                                {payDay}
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            );
    }

    const handleCardClick = (e) => {
        if (!showDescription) {
            return;
        }
        let tmpObj = amountPlanObj;
        tmpObj.selectedPlan = plan;
        setAmountPlanObj(tmpObj);
        customClickAction();
    };

    return (
        <Grid
            item
            sm={5}
            xs={12}
            className={activeClassName}
            onClick={handleCardClick}
        >
            <Box
                className={`course-payment-card outer-box grid-row ${activeClassName}`}
            >
                {cardData}
            </Box>
        </Grid>
    );
};

export default CoursePaymentCard;
